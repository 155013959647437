import { axiosAuth } from "../interceptors";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../context/appContext";
import {
  Container,
  Paper,
  Box,
  Typography,
  Button,
  TableContainer,
  IconButton,
  TextField,
  Dialog,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { removeSession } from "../services/storageService";
import useTitle from "../hooks/useTitle";
import { Group } from "../utils/interfaces";


export default function Groups() {
  const navigate = useNavigate();
  useTitle("Groups");

  const { setAlert, setAlertMessage, setAlertType } = useContext(AppContext);

  const [groups, setGroups] = useState<any>([]);

  const [groupModal, setGroupModal] = useState(false);
  const [groupData, setGroupData] = useState<any>({});

  const [editGroupModal, setEditGroupModal] = useState(false);
  const [editGroupData, setEditGroupData] = useState<Group>();

  useEffect(() => {
    axiosAuth
      .get(process.env.REACT_APP_BACKEND_URL + "/group")
      .then((response) => {
        setGroups(response.data);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          removeSession();
          navigate("/");
        }
        setAlertMessage("Could not load groups.");
        setAlertType("error");
        setAlert(true);
      });
  }, []);

  function handleEdit(group: Group) {
    axiosAuth
      .patch(process.env.REACT_APP_BACKEND_URL + "/group/" + group._id, group)
      .then((response) => {
        setAlertMessage("Group edited.");
        setAlertType("success");
        setAlert(true);
        setEditGroupModal(false);
        setGroups(
          groups.map((g: any) => (g._id === response.data._id ? response.data : g))
        );
      })
      .catch((error) => {
        if (error.response.status === 403) {
          removeSession();
          navigate("/");
        }
        setAlertMessage("Could not edit group.");
        setAlertType("error");
        setAlert(true);
      });
  }

  // function handleDelete(id: string) {

  // }

  function handleCreate() {
    axiosAuth
      .post(process.env.REACT_APP_BACKEND_URL + "/group", groupData)
      .then((response) => {
        setAlertMessage("Group created.");
        setAlertType("success");
        setAlert(true);
        setGroupModal(false);
        setGroups([...groups, response.data]);
      })
      .catch((error) => {
        setAlertMessage("Could not create group.");
        setAlertType("error");
        setAlert(true);
      });
  }

  return (
    <>
      <Container maxWidth="xl">
        <Paper sx={{ p: 4 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              Groups
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setGroupModal(true)}
            >
              Add Group <AddIcon sx={{ ml: 1 }} />
            </Button>
          </Box>

          <TableContainer sx={{ mt: 4 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 2,
                borderBottom: "1px solid #A63C46",
                p: 1,
              }}
            >
              <Typography
                variant="h6"
                sx={{ width: "90%", fontWeight: "bold" }}
              >
                Name
              </Typography>
      
              <Typography
                variant="h6"
                sx={{ width: "10%", fontWeight: "bold", textAlign: "end" }}
              >
                Actions
              </Typography>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {groups &&
                groups.map((group: Group) => (
                  <Box
                    key={group._id}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 2,
                      p: 1,
                    }}
                  >
                    <Typography variant="h6" sx={{ width: "90%" }}>
                      {group.name}
                    </Typography>
                  
                    <Typography
                      variant="h6"
                      sx={{ width: "10%", textAlign: "end" }}
                    >
                      <IconButton
                        aria-label="edit"
                        onClick={() => {
                          setEditGroupData(group);
                          setEditGroupModal(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      {/* <IconButton
                        aria-label="delete"
                        onClick={() => handleDelete(user._id)}
                      >
                        <DeleteIcon />
                      </IconButton> */}
                    </Typography>
                  </Box>
                ))}
            </Box>
          </TableContainer>
        </Paper>
      </Container>

      <Dialog open={groupModal} onClick={() => setGroupModal(false)}>
        <Paper
          sx={{ p: 4, width: 400, height: 350 }}
          elevation={3}
          onClick={(e) => e.stopPropagation()}
        >
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            Add Group
          </Typography>

          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Name*
            </Typography>
            <TextField
              sx={{ mt: 1 }}
              fullWidth
              variant="outlined"
              placeholder="Name"
              required
              onChange={(e) => {
                setGroupData({ ...groupData, name: e.target.value });
              }}
            />
          </Box>

          <Button
            sx={{ mt: 4 }}
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleCreate}
            disabled={!groupData.name}
          >
            Add Group
          </Button>
        </Paper>
      </Dialog>



      <Dialog open={editGroupModal} onClick={() => setEditGroupModal(false)}>
        <Paper
          sx={{ p: 4, width: 400, height: 350 }}
          elevation={3}
          onClick={(e) => e.stopPropagation()}
        >
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            Edit Group
          </Typography>

          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Name*
            </Typography>
            <TextField
              sx={{ mt: 1 }}
              fullWidth
              variant="outlined"
              placeholder="Name"
              required
              value={editGroupData?.name}
              onChange={(e) => {
                setEditGroupData({ ...editGroupData, name: e.target.value });
              }}
            />
          </Box>

          <Button
            sx={{ mt: 4 }}
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => handleEdit(editGroupData as Group)}
            disabled={!editGroupData?.name}
          >
            Edit Group
          </Button>
        </Paper>
      </Dialog>
    </>
  );
}
