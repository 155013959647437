import "./App.css";
import { Navbar } from "./components/Navbar";
import { AppContext } from "./context/appContext";
import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import { Login } from "./pages/Login";
import { Upload } from "./pages/Upload";
import { Profile } from "./pages/Profile";
import {
  createTheme,
  Shadows,
  Snackbar,
  ThemeProvider,
  Alert,
  Box,
  CircularProgress,
  Typography,
  CssBaseline,
  Paper,
  Container,
  useMediaQuery,
} from "@mui/material";
import Admin from "./pages/Admin";
import Groups from "./pages/Groups";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [admin, setAdmin] = useState(false);

  const [alert, setAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertType, setAlertType] = useState<string>("success");

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>("");

  const getDesignTokens = (mode: any) => ({
    typography: {
      fontFamily: "Montserrat",
    },
    palette: {
      mode,
      primary: {
        main: "#A63C46",
      },
      text: {
        primary: "#D9D9D9",
        secondary: "#C4C4C4",
      },
    },
    shadows: Array(25).fill("none") as Shadows,
  });

  useEffect(() => {
    if (localStorage.getItem("loggedIn")) {
      setLoggedIn(true);
    }

    setAdmin(
      localStorage.getItem("roles")?.includes("Admin") || false
    );
  }, [loggedIn, admin]);

  return (
    <ThemeProvider theme={createTheme(getDesignTokens("dark"))}>
      <AppContext.Provider
        value={{
          loggedIn,
          setLoggedIn,
          alert,
          setAlert,
          alertMessage,
          setAlertMessage,
          alertType,
          setAlertType,
          loading,
          setLoading,
          loadingMessage,
          setLoadingMessage,
          admin,
          setAdmin,
        }}
      >
        <Container
          sx={[
            { display: "flex", minHeight: "100vh", maxWidth: "100%" },
            useMediaQuery("(min-width:1480px)") && {
              maxWidth: "90%",
            },
          ]}
          maxWidth={false}
        >
          <BrowserRouter>
            <CssBaseline />
            <Navbar />
            <Paper
              sx={{
                p: 2,
                pt: 1,
                mt: 1,
                flex: 1,
                pb: 1,
                mb: 1,
                position: "sticky",
                top: 8,
                ml: 9,
                bottom: 8,
                display: "flex",
                width: "100%",
              }}
            >
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                {loggedIn && (
                  <>
                    {admin && <Route path="/upload" element={<Upload />} />}
                    {admin && <Route path="/admin" element={<Admin />} />}
                    {admin && <Route path="/groups" element={<Groups />} />}
                    <Route path="/profile" element={<Profile />} />
                  </>
                )}
              </Routes>
            </Paper>
          </BrowserRouter>

          <Snackbar
            open={alert}
            autoHideDuration={6000}
            onClose={() => setAlert(false)}
          >
            <Alert
              severity={alertType as "success" | "info" | "warning" | "error"}
              sx={{ position: "fixed", bottom: 48, left: 48 }}
            >
              {alertMessage}
            </Alert>
          </Snackbar>
          <Box
            sx={{
              position: "fixed",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: loading ? "flex" : "none",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 10000,
              left: 0,
              top: 0,
            }}
          >
            <CircularProgress color="primary" />
            <Typography variant="h6" sx={{ marginTop: 2 }}>
              {loadingMessage}
            </Typography>
          </Box>
        </Container>
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;
