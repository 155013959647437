import { axiosAuth } from "../interceptors";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../context/appContext";
import {
  Container,
  Paper,
  Box,
  Typography,
  Button,
  TableContainer,
  IconButton,
  TextField,
  Checkbox,
  Autocomplete,
  Dialog,
  Tooltip,
  List,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from "@mui/icons-material";
import { Md5 } from "ts-md5";
import { useNavigate } from "react-router-dom";
import { removeSession } from "../services/storageService";
import useTitle from "../hooks/useTitle";
import { User } from "../utils/interfaces";


export default function Admin() {
  const navigate = useNavigate();
  useTitle("Admin");

  const [users, setUsers] = useState<any>([]);
  const { setAlert, setAlertMessage, setAlertType } = useContext(AppContext);

  const rolesOptions = ["Admin"];

  const [groups, setGroups] = useState<any>([]);

  const [userModal, setUserModal] = useState(false);
  const [userData, setUserData] = useState<any>({});

  const [editUserModal, setEditUserModal] = useState(false);
  const [editUserData, setEditUserData] = useState<User>();

  useEffect(() => {
    axiosAuth
      .get(process.env.REACT_APP_BACKEND_URL + "/user/all")
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          removeSession();
          navigate("/");
        }
        setAlertMessage("Could not load users.");
        setAlertType("error");
        setAlert(true);
      });

    axiosAuth
      .get(process.env.REACT_APP_BACKEND_URL + "/group")
      .then((response) => {
        setGroups(response.data);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          removeSession();
          navigate("/");
        }
        setAlertMessage("Could not load groups.");
        setAlertType("error");
        setAlert(true);
      });
  }, []);

  function handleEdit(user: User) {
    axiosAuth
      .patch(process.env.REACT_APP_BACKEND_URL + "/user/update/" + user._id, user)
      .then((response) => {
        setAlertMessage("User updated.");
        setAlertType("success");
        setAlert(true);
        setUsers(
          users.map((u: User) => (u._id === user._id ? user : u))
        );
        setEditUserModal(false);
        setEditUserData(undefined);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setAlertMessage("Unauthorized.");
          setAlertType("error");
          setAlert(true);
        } else if (error.response.status === 409) {
          setAlertMessage("Username already exists.");
          setAlertType("error");
          setAlert(true);
        } else {
          setAlertMessage("Could not update user.");
          setAlertType("error");
          setAlert(true);
        }
      });
  }

  function handleDelete(id: string) {
    axiosAuth
    .delete(process.env.REACT_APP_BACKEND_URL + "/user/delete/" + id)
    .then((response) => {
      setAlertMessage("User deleted.");
      setAlertType("success");
      setAlert(true);
      
      setUsers(users.filter((user: any) => user._id !== id));
    })
  }

  function handleUserCreate() {
    axiosAuth
      .post(process.env.REACT_APP_BACKEND_URL + "/user/register", userData)
      .then((response) => {
        setAlertMessage("User created.");
        setAlertType("success");
        setAlert(true);
        setUserModal(false);
        setUsers([...users, response.data]);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setAlertMessage("Unauthorized.");
          setAlertType("error");
          setAlert(true);
        } else if (error.response.status === 409) {
          setAlertMessage("Username already exists.");
          setAlertType("error");
          setAlert(true);
        } else {
          setAlertMessage("Could not create user.");
          setAlertType("error");
          setAlert(true);
        }
      });
  }

  return (
    <>
      <Container maxWidth="xl">
        <Paper sx={{ p: 4 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              Users
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setUserModal(true)}
            >
              Add User <AddIcon sx={{ ml: 1 }} />
            </Button>
          </Box>

          <TableContainer sx={{ mt: 4 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 2,
                borderBottom: "1px solid #A63C46",
                p: 1,
              }}
            >
              <Typography
                variant="h6"
                sx={{ width: "30%", fontWeight: "bold" }}
              >
                Username
              </Typography>
              <Typography
                variant="h6"
                sx={{ width: "20%", fontWeight: "bold" }}
              >
                Upload Count
              </Typography>
              <Typography
                variant="h6"
                sx={{ width: "20%", fontWeight: "bold" }}
              >
                Upload Limit
              </Typography>
              <Tooltip
                title={
                  <List sx={{p: 1}}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Admin
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      - Can upload files
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      - Can create users
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      - Can edit users
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      - Can delete users
                    </Typography>
                  </List>
                }
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#111",
                    },
                  },
                }}
                placement="bottom-start"
              >
                <Typography variant="h6" sx={{ fontWeight: "bold", width: "20%" }}>
                  Roles
                </Typography>
              </Tooltip>
                
              <Typography
                variant="h6"
                sx={{ width: "20%", fontWeight: "bold", }}
              >
                Groups
              </Typography>

              <Typography
                variant="h6"
                sx={{ width: "10%", fontWeight: "bold", textAlign: "end" }}
              >
                Actions
              </Typography>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {users &&
                users.map((user: any) => (
                  <Box
                    key={user.id}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: 2,
                      p: 1,
                    }}
                  >
                    <Typography variant="h6" sx={{ width: "30%" }}>
                      {user.username}
                    </Typography>
                    <Typography variant="h6" sx={{ width: "20%" }}>
                      {user.roles.includes("Admin")
                        ? (user.uploadCount
                            ? (user.uploadCount / 1_000_000).toFixed(0)
                            : 0) + " MB"
                        : "-"}
                    </Typography>
                    <Typography variant="h6" sx={{ width: "20%" }}>
                      {user.roles.includes("Admin")
                        ? (user.uploadLimit
                            ? (user.uploadLimit / 1_000_000).toFixed(0)
                            : 0) + " MB"
                        : "-"}
                    </Typography>
                    <Typography variant="h6" sx={{ width: "20%" }}>
                      {user.roles.join(", ") || "-"}
                    </Typography>
                    <Typography variant="h6" sx={{ width: "20%" }}>
                      {user.groups.map((group: any) => group.name).join(", ") || "-"}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ width: "10%", textAlign: "end" }}
                    >
                      <IconButton
                        aria-label="edit"
                        onClick={() => {
                          setEditUserData(user);
                          setEditUserModal(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDelete(user._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Typography>
                  </Box>
                ))}
            </Box>
          </TableContainer>
        </Paper>
      </Container>

      <Dialog open={userModal} onClick={() => setUserModal(false)}>
        <Paper
          sx={{ p: 4, width: 400, height: 650 }}
          elevation={3}
          onClick={(e) => e.stopPropagation()}
        >
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            Add User
          </Typography>

          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Username*
            </Typography>
            <TextField
              sx={{ mt: 1 }}
              fullWidth
              variant="outlined"
              placeholder="Username"
              required
              onChange={(e) => {
                setUserData({ ...userData, username: e.target.value });
              }}
            />
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Password*
            </Typography>
            <TextField
              sx={{ mt: 1 }}
              fullWidth
              variant="outlined"
              required
              placeholder="Password"
              onChange={(e) => {
                setUserData({
                  ...userData,
                  password: new Md5().appendStr(e.target.value).end(),
                });
              }}
            />
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Roles
            </Typography>
            <Autocomplete
              multiple
              id="roles"
              limitTags={2}
              options={rolesOptions}
              disableCloseOnSelect
              sx={{
                flex: 1,
                mt: 1,
              }}
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    sx={{ mr: 1 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Roles" placeholder="Roles" />
              )}
              onChange={(e, value) => {
                setUserData({ ...userData, roles: value });
              }}
            />
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Groups
            </Typography>
            <Autocomplete
              multiple
              id="groups"
              limitTags={2}
              options={groups}
              disableCloseOnSelect
              sx={{
                flex: 1,
                mt: 1,
              }}
              value={userData?.groups || []}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    sx={{ mr: 1 }}
                    checked={userData?.groups?.includes(option)}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Groups" placeholder="Groups" />
              )}
              onChange={(e, value) => {
                setUserData({ ...userData, groups: value });
              }}
            />
          </Box>

          <Button
            sx={{ mt: 4 }}
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleUserCreate}
            disabled={!userData.username || !userData.password}
          >
            Add User
          </Button>
        </Paper>
      </Dialog>



      <Dialog open={editUserModal} onClick={() => setEditUserModal(false)}>
        <Paper
          sx={{ p: 4, width: 400, height: 750 }}
          elevation={3}
          onClick={(e) => e.stopPropagation()}
        >
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            Edit User
          </Typography>

          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Username*
            </Typography>
            <TextField
              sx={{ mt: 1 }}
              fullWidth
              variant="outlined"
              placeholder="Username"
              required
              value={editUserData?.username}
              onChange={(e) => {
                setEditUserData({ ...editUserData, username: e.target.value });
              }}
            />
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Password
            </Typography>
            <TextField
              sx={{ mt: 1 }}
              fullWidth
              variant="outlined"
              placeholder="Password"
              onChange={(e) => {
                setEditUserData({
                  ...editUserData,
                  password: new Md5().appendStr(e.target.value).end()?.toString(),
                });
              }}
            />
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Upload Limit
            </Typography>
            <TextField
              sx={{ mt: 1 }}
              fullWidth
              variant="outlined"
              placeholder="Upload Limit"
              value={editUserData?.uploadLimit}
              onChange={(e) => {
                setEditUserData({
                  ...editUserData,
                  uploadLimit: parseInt(e.target.value),
                });
              }}
            />
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Roles
            </Typography>
            <Autocomplete
              multiple
              id="roles"
              limitTags={2}
              options={rolesOptions}
              disableCloseOnSelect
              sx={{
                flex: 1,
                mt: 1,
              }}
              value={editUserData?.roles || []}
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    sx={{ mr: 1 }}
                    checked={editUserData?.roles?.includes(option)}
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Roles" placeholder="Roles" />
              )}
              onChange={(e, value) => {
                setEditUserData({ ...editUserData, roles: value });
              }}
            />
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Groups
            </Typography>
            <Autocomplete
              multiple
              id="groups"
              limitTags={2}
              options={groups}
              disableCloseOnSelect
              sx={{
                flex: 1,
                mt: 1,
              }}
              value={editUserData?.groups || []}
              getOptionLabel={(option) => option.name as string}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    sx={{ mr: 1 }}
                    checked={editUserData?.groups?.find((g) => g._id === option._id) !== undefined}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Groups" placeholder="Groups" />
              )}
              onChange={(e, value) => {
                setEditUserData({ ...editUserData, groups: value });
              }}
            />
          </Box>

          <Button
            sx={{ mt: 4 }}
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => handleEdit(editUserData as User)}
            disabled={!editUserData?.username}
          >
            Edit User
          </Button>
        </Paper>
      </Dialog>
    </>
  );
}
