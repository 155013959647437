import { Md5} from "ts-md5";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  TextField,
  Typography,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
  Paper,
} from "@mui/material";

import { loginUser } from "../services/authService";
import { useState } from "react";
import { useContext } from "react";
import { AppContext } from "../context/appContext";
import useTitle from "../hooks/useTitle";

export function Login() {
  const navigate = useNavigate();
  useTitle("Login");

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { setLoggedIn, setLoading, setLoadingMessage, loading } = useContext(AppContext);
  const md5 = new Md5();

  function handleSubmit(event: any) {
    setLoadingMessage("Logging in...");
    setLoading(true);

    event.preventDefault();
    const data = new FormData(event.currentTarget);
    loginUser({
      username: data.get("username"),
      password: md5.appendStr(data.get("password") as string).end(),
    })
      .then((response) => {
        setLoading(false);
        setLoadingMessage("");
        setLoggedIn(true);
        navigate("/");
      })
      .catch((error) => {
        setLoading(false);
        setLoadingMessage("");
        if (error.response.status === 422) {
          setErrorMessage("Fill in all fields");
        } else if (error.response.status === 404) {
          setErrorMessage("Invalid credentials");
        } else {
          setErrorMessage("Something went wrong");
        }
        setError(true);
      });
  }

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          px: 4,
          py: 6,
          marginTop: 16,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoFocus
            inputProps={{sx: { boxShadow: 'none !important'}}}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            inputProps={{sx: { boxShadow: 'none !important'}}}
          />
          {loading ? (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                mt: 3,
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>
          )}
        </Box>
        <Typography component="h1" variant="body2" sx={{textAlign: 'center', color: 'grey', mt: 2}}>
        Account creation is currently reserved for whitelisting purposes and not available for public use.
        </Typography>
      </Paper>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => setError(false)}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
