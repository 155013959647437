import { useState, useRef, useContext, useEffect } from "react";
import {
  Container,
  Paper,
  Button,
  Grid,
  Typography,
  TextField,
  Select,
  Box,
  Chip,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel,
  FormControl,
  FormControlLabel,
  Autocomplete,
  List,
  ListItem,
  IconButton,
} from "@mui/material";

import { axiosAuth } from "../interceptors";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../context/appContext";
import { Delete as DeleteIcon, Error as ErrorIcon, Add as AddIcon } from "@mui/icons-material";
import useTitle from "../hooks/useTitle";
import { Group, SkinPackForm, Tag, User } from "../utils/interfaces";

const tagsRegex = /\(([^)]*)\)/;

export function Upload() {
  const navigate = useNavigate();
  useTitle("Upload");

  const {
    setAlert,
    setAlertMessage,
    setAlertType,
    setLoading,
    setLoadingMessage,
  } = useContext(AppContext);

  const [users, setUsers] = useState<User[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);

  const [badFiles, setBadFiles] = useState<string[]>([]);

  const [tagTypes, setTagTypes] = useState<Tag[]>([]);

  useEffect(() => {
    axiosAuth
      .get(process.env.REACT_APP_BACKEND_URL + "/user/all")
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          navigate("/");
          setAlertMessage("You do not have permission to view this page.");
          setAlertType("error");
          setAlert(true);
        } else {
          setAlertMessage("Could not load users.");
          setAlertType("error");
          setAlert(true);
        }
      });

    axiosAuth
      .get(process.env.REACT_APP_BACKEND_URL + "/group")
      .then((response) => {
        setGroups(response.data);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          navigate("/");
          setAlertMessage("You do not have permission to view this page.");
          setAlertType("error");
          setAlert(true);
        } else {
          setAlertMessage("Could not load groups.");
          setAlertType("error");
          setAlert(true);
        }
      });

    axiosAuth
      .get(process.env.REACT_APP_BACKEND_URL + "/tag")
      .then((response) => {
        setTagTypes(response.data);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          navigate("/");
          setAlertMessage("You do not have permission to view this page.");
          setAlertType("error");
          setAlert(true);
        } else {
          setAlertMessage("Could not load tags.");
          setAlertType("error");
          setAlert(true);
        }
      });
  }, []);

  const [imageUrls, setImageUrls] = useState([
    {
      file: {
        name: "",
        webkitRelativePath: "",
      },
      fileContents: "",
    },
  ]);
  const inputFolder = useRef<HTMLInputElement | null>(null);

  const [formData, setFormData] = useState<SkinPackForm>({
    name: "",
    description: "",
    variations: [],
    displayImage: "",
    files: [],
    allowedAccess: [],
    groupAllowedAccess: [],
    public: true,
    tags: [],
  });

  const [possibleVariations, setPossibleVariations] = useState<string[]>([]);

  const [displayImagePlaceholder, setDisplayImagePlaceholder] = useState<{
    file: any;
    fileContents: any;
  }>();

  const handleFileUpload = (event: any) => {
    const dt = new DataTransfer();
    let errorFiles = [];
    for (const file of event.target.files) {
      if (file.size >= 25 * 1024 * 1024) {
        setAlertMessage("File size too large.");
        setAlertType("error");
        setAlert(true);

        errorFiles.push(file);
      } else {
        dt.items.add(file);
      }
    }

    event.target.files = dt.files;
    if (errorFiles.length > 0) {
      setBadFiles(errorFiles.map((file) => window.URL.createObjectURL(file)));
    } else {
      setBadFiles([]);
    }

    if (event.target.files.length === 0) {
      return;
    }

    let name = formData.name
      ? formData.name
      : event.target.files[0].webkitRelativePath.split("/")[0];

    let variationList: string[] = [];
    for (const file of event.target.files) {
      if (file.webkitRelativePath.split("/").length > 2) {
        let lastDir = file.webkitRelativePath.split("/");
        lastDir.pop();
        lastDir = lastDir.pop();
        if (!variationList.includes(lastDir)) {
          variationList.push(lastDir);
        }
      }
    }

    setPossibleVariations([...variationList]);

    let fileList: any[] = [];
    for (const file of event.target.files) {
      fileList.push(file);
    }

    const handleFileChosen = async (file: any) => {
      return new Promise((resolve, reject) => {
        let fileReader = new FileReader();
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = reject;
        fileReader.readAsDataURL(file);
      });
    };

    const readAllFiles = async (AllFiles: any) => {
      const results = await Promise.all(
        AllFiles.map(async (file: any) => {
          const fileContents = await handleFileChosen(file);
          return { file, fileContents };
        })
      );
      return results;
    };

    let allFiles = [];
    for (const file of event.target.files) {
      allFiles.push(file);
    }

    readAllFiles(allFiles).then((results) => {
      setFormData({
        ...formData,
        name,
        displayImage: results[0].file.webkitRelativePath,
        files: fileList,
      });

      setDisplayImagePlaceholder(results[0]);

      setImageUrls(results as any);
    });
  };

  function handleDisplayUpload(event: any) {
    if (event.target.files[0].size >= 25 * 1024 * 1024) {
      setAlertMessage("File size too large.");
      setAlertType("error");
      setAlert(true);
      return;
    }

    let fileReader = new FileReader();
    let file = new File(
      [event.target.files[0]],
      formData.files[0].webkitRelativePath.split("/")[0] +
        "/" +
        event.target.files[0].name
    );

    setFormData({
      ...formData,
      displayImage: file.name,
      displayFile: file,
    });

    fileReader.onload = () => {
      let result = { file, fileContents: fileReader.result };
      setDisplayImagePlaceholder(result as any);
    };
    fileReader.readAsDataURL(event.target.files[0]);
  }

  function handleForm(event: any) {
    event.preventDefault();

    const fd = new FormData();

    fd.append("name", formData.name);
    fd.append("description", formData.description);
    fd.append("displayImage", formData.displayImage);
    fd.append("public", formData.public.toString());

    let variationList = [];
    for (const variation of formData.variations) {
      variationList.push(variation);
    }
    fd.append("variations", JSON.stringify(variationList));

    for (const file of formData.files) {
      fd.append("files", file, window.btoa(file.webkitRelativePath));
    }

    if (formData.displayFile) {
      fd.append("displayFile", formData.displayFile.name);
      fd.append(
        "files",
        formData.displayFile,
        window.btoa(formData.displayFile.name)
      );
    }

    let allowedAccessList = [];
    for (const allowedAccess of formData.allowedAccess) {
      allowedAccessList.push(allowedAccess);
    }
    fd.append("allowedAccess", JSON.stringify(allowedAccessList));

    let groupAllowedAccessList = [];
    for (const groupAllowedAccess of formData.groupAllowedAccess) {
      groupAllowedAccessList.push(groupAllowedAccess);
    }
    fd.append("groupAllowedAccess", JSON.stringify(groupAllowedAccessList));

    fd.append("tags", JSON.stringify(formData.tags));

    setLoadingMessage("Uploading skinpack...");
    setLoading(true);

    axiosAuth
      .post(process.env.REACT_APP_BACKEND_URL + "/skinpack", fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setLoading(false);
        setLoadingMessage("");
        if (response.status === 200) {
          setAlertMessage("Skinpack uploaded successfully.");
          setAlertType("success");
          setAlert(true);
          navigate("/");
        }
      })
      .catch((error) => {
        setLoading(false);
        setLoadingMessage("");
        if (error.response.status === 403) {
          setAlertMessage(error.response.data.message);
        } else {
          setAlertMessage("Could not upload skinpack.");
        }
        setAlertType("error");
        setAlert(true);
      });
  }

  function handleChange(event: any) {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  }

  function handleDisplayImage(imageUrl: any) {
    setFormData({
      ...formData,
      displayImage: imageUrl.file.webkitRelativePath,
      displayFile: undefined,
    });
    setDisplayImagePlaceholder(imageUrl);
  }

  useEffect(() => {
    const matches = formData.name.match(tagsRegex);

    if (matches && matches.length > 1) {
      const items = matches[1].split(",").map((item) => item.trim()).filter((item) => item !== "");

      if (items.length > 0) {
        setFormData(
          {
            ...formData,
            tags: items.map((item) => {
              const type = tagTypes.find((tag) => tag.name.toLowerCase() === item.toLowerCase());
              return { name: item, type: type ? type.type : "Color" };
            })
          }
        );
      }
    }
  }, [formData.name, tagTypes]);

  function handleChangeTags(event: any) {
    setFormData({
      ...formData,
      tags: formData.tags?.filter((tag) => !event.target.value.includes(tag.name))
    });
  }

  return (
    <Container>
      <Typography variant="h4" component="h1" sx={{ mb: 2 }}>
        Upload Skinpack
      </Typography>

      <Paper sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <label htmlFor="upload-image">
              <Button variant="contained" component="span" sx={{ mb: 2 }}>
                Select Skinpack
              </Button>
              <input
                id="upload-image"
                hidden
                accept="image/*,.webm"
                type="file"
                name="files"
                multiple
                ref={(node) => {
                  inputFolder.current = node;

                  if (node) {
                    ["webkitdirectory", "directory", "mozdirectory"].forEach(
                      (attr) => {
                        node.setAttribute(attr, "");
                      }
                    );
                  }
                }}
                onChange={handleFileUpload}
              />
            </label>

            <form onSubmit={handleForm} style={{ width: 300 }}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                margin="normal"
              />

              <TextField
                fullWidth
                label="Description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                margin="normal"
              />

              <FormControl variant="outlined" sx={{ width: "100%" }}>
                <InputLabel id="variations-label" sx={{ mt: 2 }}>
                  Variations
                </InputLabel>
                <Select
                  fullWidth
                  labelId="variations-label"
                  label="Variations"
                  name="variations"
                  value={formData.variations}
                  onChange={handleChange}
                  multiple
                  sx={{
                    mb: 2,
                    mt: 2,
                  }}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {(selected as string[]).map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {possibleVariations.map((variation) => (
                    <MenuItem key={variation} value={variation}>
                      <Checkbox
                        checked={
                          formData.variations.indexOf(variation as never) > -1
                        }
                      />
                      <ListItemText primary={variation} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              
              <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <InputLabel>
                  Tags
                  </InputLabel>
                <IconButton
                  onClick={() => {
                    const newTag = window.prompt("Enter new tag name");
                    if (newTag) {
                      const newTagType = window.prompt("Enter new tag type: Pack | Type | Color");
                      
                      if (newTagType) {
                        setFormData({
                          ...formData,
                          tags: formData.tags ? [...formData.tags as [], {name: newTag, type: newTagType}] : [{name: newTag, type: newTagType}]
                        });
                      }
                    }
                  }} 
                >
                  <AddIcon 
                  />
                </IconButton>
              </Box>
                <FormControl
                variant="outlined"
                sx={{ width: "100%", mb: 2 }}
                >
                  <Select
                    fullWidth
                    name="tags"
                    value={formData.tags}
                    onChange={handleChangeTags}
                    multiple
                    sx={{
                      mb: 2,
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {(selected).slice(0, 4).map((value) => (
                          <Chip label={`${value.name} (${value.type})`} />
                        ))}
                        {selected.length > 4 && <Chip label={`+${selected.length - 4} more`} />}
                      </Box>
                    )}
                    
                  >
                    {formData.tags.map((tag) => (
                      <MenuItem key={tag.name} value={tag.name}>
                        <Checkbox
                          checked={
                            formData.tags?.includes(tag) || false
                          }
                        />
                        <ListItemText primary={tag.name} secondary={tag.type} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>


              <Box sx={{ display: "flex" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.public}
                      onChange={() =>
                        setFormData({ ...formData, public: !formData.public })
                      }
                      name="public"
                      color="primary"
                    />
                  }
                  label="Public"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!formData.public}
                      onChange={() =>
                        setFormData({ ...formData, public: !formData.public })
                      }
                      name="public"
                      color="primary"
                    />
                  }
                  label="Private"
                />
              </Box>

              {!formData.public && (
                <Box>
                  <Autocomplete
                    key={"allowed-access"}
                    multiple
                    id="allowed-access"
                    limitTags={2}
                    options={users}
                    sx={{ mt: 2 }}
                    getOptionLabel={(option) => option.username as string}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          checked={selected}
                          sx={{ mr: 2 }}
                          color="primary"
                        />
                        {option.username}
                      </li>
                    )}
                    onChange={(event, value) => {
                      setFormData({
                        ...formData,
                        allowedAccess: value.map((user) => user._id),
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Allowed Access"
                        placeholder="Allowed Access"
                      />
                    )}
                  />

                  <Autocomplete
                    key={"group-allowed-access"}
                    multiple
                    id="group-allowed-access"
                    limitTags={2}
                    options={groups}
                    sx={{ mt: 2 }}
                    getOptionLabel={(option) => option.name as string}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          checked={selected}
                          sx={{ mr: 2 }}
                          color="primary"
                        />
                        {option.name}
                      </li>
                    )}
                    onChange={(event, value) => {
                      setFormData({
                        ...formData,
                        groupAllowedAccess: value.map((group) => group._id),
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Group Allowed Access"
                        placeholder="Group Allowed Access"
                      />
                    )}
                  />
                </Box>
              )}

              <InputLabel sx={{ mt: 2, mb: 1 }}>&nbsp;Display Image</InputLabel>
              <label htmlFor="upload-display">
                <Button
                  variant="contained"
                  component="span"
                  sx={{ mb: 2 }}
                  disabled={formData.files.length === 0}
                >
                  Upload Display Image
                </Button>
                <input
                  id="upload-display"
                  hidden
                  accept="image/*,.webm"
                  type="file"
                  name="files"
                  onChange={handleDisplayUpload}
                />
              </label>

              {displayImagePlaceholder &&
                (displayImagePlaceholder.file.name.split(".").pop() ===
                "webm" ? (
                  <video
                    src={displayImagePlaceholder.fileContents}
                    style={{ width: "100%" }}
                    autoPlay
                    loop
                    muted
                  />
                ) : (
                  <img
                    src={displayImagePlaceholder.fileContents}
                    alt="skin"
                    style={{ width: "100%" }}
                  />
                ))}

              <Button
                variant="contained"
                type="submit"
                sx={{ mt: 2, mb: 2 }}
                disabled={formData.name === ""}
              >
                Upload
              </Button>
            </form>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {badFiles.length > 0 && (
                <Box sx={{ display: "flex", flexDirection: "column", mb: 4 }}>
                  <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
                    File Size Too Large
                  </Typography>

                  <Grid container spacing={2}>
                    {badFiles.map((badFile) => (
                      <Grid
                        item
                        xs={3}
                        key={badFile}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            position: "relative",
                          }}
                        >
                          <img
                            src={badFile}
                            key={badFile}
                            alt=""
                            style={{ width: "100%" }}
                          />
                          <Box
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              backgroundColor: "rgba(0,0,0,0.5)",
                            }}
                          >
                            <ErrorIcon
                              sx={{
                                position: "relative",
                                top: "10%",
                                left: "35%",
                                width: 40,
                                height: 40,
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
              <Box>
                <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
                  Skinpack Preview
                </Typography>

                <Grid container spacing={2}>
                  {imageUrls.map((imageUrl) => (
                    <Grid
                      item
                      xs={3}
                      key={imageUrl.file.webkitRelativePath}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {imageUrl.file.webkitRelativePath.split(".").pop() ===
                      "webm" ? (
                        <video
                          src={imageUrl.fileContents}
                          key={imageUrl.file.webkitRelativePath}
                          style={{ width: "100%" }}
                          onClick={() => handleDisplayImage(imageUrl)}
                          autoPlay
                          loop
                          muted
                        />
                      ) : (
                        <img
                          src={imageUrl.fileContents}
                          key={imageUrl.file.webkitRelativePath}
                          alt=""
                          style={{ width: "100%" }}
                          onClick={() => handleDisplayImage(imageUrl)}
                        />
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
