import { axiosAuth } from "../interceptors";
import { useEffect, useState, useContext, useRef } from "react";
import {
  Box,
  Paper,
  Typography,
  Container,
  TextField,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Chip,
  MenuItem,
  Select,
  InputLabel,
  ListItemText,
  FormControl,
  MenuList,
  Divider,
  IconButton,
  FormGroup,
  RadioGroup,
  Radio,
  Modal,
} from "@mui/material";
import { SkinPackCard } from "../components/SkinPackCard";
import { SkinCard } from "../components/SkinCard";
import { AppContext } from "../context/appContext";
import {
  Save as SaveIcon,
  ContentCopy as ContentCopyIcon,
  Delete as DeleteIcon,
  Brush as BrushIcon,
  Image as ImageIcon,
  VisibilityOff as VisibilityOffIcon,
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Upload as UploadIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { ReactComponent as OverlayIcon } from "../assets/OverlayIcon.svg";
// import { ContextMenu } from "../components/ContextMenu";
import useContextMenu from "../hooks/useContextMenu";

import { ReactComponent as SizesIcon } from "../assets/SizeIcon.svg";

import ProgressBar from "@ramonak/react-progress-bar";
import { logoutUser } from "../services/authService";
import { useNavigate } from "react-router-dom";
import useTitle from "../hooks/useTitle";
import {
  Group,
  InputProfile,
  InputSkin,
  InputSkinPack,
  OrderPreset,
  Skin,
  SkinPack,
  User,
  UserData,
} from "../utils/interfaces";

export function Profile() {
  const navigate = useNavigate();
  useTitle("Profile");

  const { clicked, setClicked, points, setPoints, subMenu, setSubMenu } =
    useContextMenu();

  const [editPackColors, setEditPackColors] = useState(false);
  const [packColors, setPackColors] = useState<{
    background?: string;
    icon?: string;
  }>();

  const [userData, setUserData] = useState<UserData>();
  const [users, setUsers] = useState<User[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);

  const videoRef = useRef<any[]>([]);

  const [username, setUsername] = useState<string>("");
  const [currentSkinPacks, setCurrentSkinPacks] = useState<SkinPack[]>([]);
  const [currentSkinPack, setCurrentSkinPack] = useState<SkinPack>();
  const [currentSkin, setCurrentSkin] = useState<Skin>();
  const [oldPack, setOldPack] = useState<SkinPack>();
  const [skinPackOrder, setSkinPackOrder] = useState<
    {
      subPack: SkinPack;
      order: number;
      _id?: string;
    }[]
  >([]);

  const [defaultVariations, setDefaultVariations] = useState<string[]>([]);
  const [currentVariations, setCurrentVariations] = useState<string[]>([]);

  const [defaultTags, setDefaultTags] = useState<
    { name: string; type: string }[]
  >([]);
  const [currentTags, setCurrentTags] = useState<
    { name: string; type: string }[]
  >([]);

  const {
    setAlert,
    setAlertMessage,
    setAlertType,
    setLoading,
    setLoadingMessage,
    admin,
  } = useContext(AppContext);

  const [formDataProfile, setFormDataProfile] = useState<InputProfile>();
  const [formDataSkinPack, setFormDataSkinPack] = useState<InputSkinPack>();
  const [formDataSkins, setFormDataSkins] = useState<InputSkin[]>();

  const [deleteItem, setDeleteItem] = useState("");

  const [displayUpload, setDisplayUpload] = useState<any>();

  const inputSubPacks = useRef<HTMLInputElement | null>(null);

  const [modalLoadPreset, setModalLoadPreset] = useState(false);
  const [orderPresets, setOrderPresets] = useState<OrderPreset[]>([]);
  const [selectedPreset, setSelectedPreset] = useState<OrderPreset>();

  useEffect(() => {
    axiosAuth
      .get(process.env.REACT_APP_BACKEND_URL + "/user/me")
      .then((response) => {
        setUserData(response.data);
        setUsername(response.data.username);
      })
      .catch((error) => {
        setAlertMessage("Could not load profile.");
        setAlertType("error");
        setAlert(true);
      });

    axiosAuth
      .get(process.env.REACT_APP_BACKEND_URL + "/group")
      .then((response) => {
        setGroups(response.data);
      })
      .catch((error) => {
        setAlertMessage("Could not load groups.");
        setAlertType("error");
        setAlert(true);
      });

    admin &&
      axiosAuth
        .get(process.env.REACT_APP_BACKEND_URL + "/user/all")
        .then((response) => {
          setUsers(response.data);
        })
        .catch((error) => {
          setAlertMessage("Could not load users.");
          setAlertType("error");
          setAlert(true);
        });
  }, []);

  useEffect(() => {
    if (currentSkinPack) {
      let packChanged = true;
      userData?.skinPacks?.forEach((pack) => {
        if (pack === currentSkinPack) {
          packChanged = false;
          return;
        } else {
          if (
            pack.subPacks?.find(
              (subPack) => subPack.subPack === currentSkinPack
            )
          ) {
            packChanged = false;
            return;
          }
        }
      });

      if (packChanged) {
        if (oldPack) {
          let changedFields = Object.keys(currentSkinPack).filter((key) => {
            if (typeof currentSkinPack[key as never] !== "object") {
              return currentSkinPack[key as never] !== oldPack[key as never];
            } else {
              if (key === "skins") {
                let skinsChanged = false;
                currentSkinPack["skins"]?.forEach((skin: any) => {
                  if (oldPack["skins"]?.find((s: any) => s._id === skin._id)) {
                    if (
                      skin.order !==
                      oldPack["skins"]?.find((s: any) => s._id === skin._id)
                        ?.order
                    ) {
                      skinsChanged = true;
                    }
                  } else {
                    skinsChanged = true;
                  }
                });

                return skinsChanged;
              } else if (key === "displayImage") {
                return (
                  currentSkinPack["displayImage"]?._id !==
                  oldPack["displayImage"]?._id
                );
              } else if (key === "allowedAccess") {
                return (
                  JSON.stringify(currentSkinPack["allowedAccess"]) !==
                  JSON.stringify(oldPack["allowedAccess"])
                );
              } else if (key === "groupAllowedAccess") {
                return (
                  JSON.stringify(currentSkinPack["groupAllowedAccess"]) !==
                  JSON.stringify(oldPack["groupAllowedAccess"])
                );
              } else {
                return false;
              }
            }
          });

          if (changedFields.length > 0) {
            let newSkinPack = {
              _id: currentSkinPack._id,
              name: currentSkinPack.name,
              public: currentSkinPack.public,
              fullPath: currentSkinPack.fullPath,
              url: currentSkinPack.url,
              submitter: currentSkinPack.submitter,
            };
            Object.keys(currentSkinPack).forEach((key) => {
              if (changedFields.includes(key)) {
                newSkinPack[key as never] = currentSkinPack[key as never];
              }
            });

            setFormDataSkinPack(newSkinPack);
          } else {
            setFormDataSkinPack(undefined);
          }
        }
      } else {
        setFormDataSkinPack(undefined);
      }
    }
  }, [currentSkinPack, oldPack, userData?.skinPacks]);

  useEffect(() => {
    if (currentSkin) {
      let skinChanged = false;
      let oldSkin = oldPack?.skins?.find(
        (skin) => skin.skin._id === currentSkin._id
      )?.skin;

      if (oldSkin) {
        Object.keys(currentSkin).forEach((key) => {
          if (oldSkin && currentSkin[key as never] !== oldSkin[key as never]) {
            skinChanged = true;
            return;
          }
        });
      }

      if (skinChanged) {
        let newSkin = {};
        Object.keys(currentSkin).forEach((key) => {
          if (key !== "submitter")
            if (
              oldSkin &&
              currentSkin[key as never] !== oldSkin[key as never]
            ) {
              newSkin[key as never] = currentSkin[key as never];
            }
        });

        if (Object.keys(newSkin).length > 0) {
          newSkin["_id" as never] = currentSkin._id as never;
        }

        setCurrentSkinPack({
          ...currentSkinPack,
          skins: currentSkinPack?.skins?.map((skin) => {
            if (skin.skin._id === currentSkin._id) {
              return {
                ...skin,
                skin: currentSkin as Skin,
              };
            } else {
              return skin;
            }
          }),
        } as SkinPack);

        if (formDataSkins && formDataSkins.length > 0) {
          let newFormDataSkin = [...formDataSkins];

          if (newFormDataSkin.find((skin) => skin._id === currentSkin._id)) {
            newFormDataSkin = newFormDataSkin.map((skin) => {
              if (skin._id === currentSkin._id) {
                return {
                  ...skin,
                  ...newSkin,
                };
              } else {
                return skin;
              }
            });
          } else {
            newFormDataSkin.push(newSkin as InputSkin);
          }

          setFormDataSkins(
            newFormDataSkin.filter((skin) => Object.keys(skin).length > 1)
          );
        } else if (Object.keys(newSkin).length > 0) {
          setFormDataSkins([newSkin as InputSkin]);
        }
      }
    }
  }, [currentSkin]);

  useEffect(() => {
    if (packColors) {
      (async () => {
        if (
          currentSkinPack &&
          currentSkinPack.skins &&
          currentSkinPack.skins.length > 0
        ) {
          let newSkins: InputSkin[] = [];
          await Promise.all(
            currentSkinPack.skins.map((skin) => {
              return new Promise((resolve, reject) => {
                let newSkin: InputSkin = {
                  _id: skin.skin._id,
                };
                if (packColors?.background)
                  newSkin.backgroundColor = packColors.background;
                if (packColors?.icon) newSkin.iconColor = packColors.icon;

                newSkins.push(newSkin);
                resolve(newSkin);
              });
            })
          );

          if (formDataSkins && formDataSkins.length > 0) {
            let newFormDataSkin = [...formDataSkins];

            newFormDataSkin = newFormDataSkin.map((skin) => {
              if (newSkins.find((s: InputSkin) => s._id === skin._id)) {
                let newSkin = {
                  ...skin,
                };

                if (
                  newSkins.find((s: InputSkin) => s._id === skin._id)
                    ?.backgroundColor
                ) {
                  newSkin.backgroundColor = newSkins.find(
                    (s: InputSkin) => s._id === skin._id
                  )?.backgroundColor;
                } else {
                  delete newSkin.backgroundColor;
                }

                if (
                  newSkins.find((s: InputSkin) => s._id === skin._id)?.iconColor
                ) {
                  newSkin.iconColor = newSkins.find(
                    (s: InputSkin) => s._id === skin._id
                  )?.iconColor;
                } else {
                  delete newSkin.iconColor;
                }

                return newSkin;
              } else {
                return skin;
              }
            });

            setFormDataSkins(
              newFormDataSkin.filter((skin) => Object.keys(skin).length > 1)
            );
          } else {
            setFormDataSkins(newSkins);
          }
        }
      })();
    } else {
      if (currentSkinPack) {
        if (formDataSkins && formDataSkins.length > 0) {
          let newFormDataSkin = [...formDataSkins];

          newFormDataSkin = newFormDataSkin.map((skin) => {
            let newSkin = {
              ...skin,
            };

            if (skin.backgroundColor) delete newSkin.backgroundColor;
            if (skin.iconColor) delete newSkin.iconColor;

            return newSkin;
          });

          setFormDataSkins(
            newFormDataSkin.filter((skin) => Object.keys(skin).length > 0)
          );
        }
      }
    }
  }, [packColors]);

  function handleFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    let inputDataProfile = structuredClone(formDataProfile);
    let inputDataSkinsPack = structuredClone(formDataSkinPack);
    let inputDataSkins = structuredClone(formDataSkins);

    if (
      JSON.stringify(currentVariations.sort()) !==
      JSON.stringify(defaultVariations.sort())
    ) {
      if (currentSkinPack) {
        if (!inputDataSkinsPack)
          inputDataSkinsPack = {
            _id: currentSkinPack._id,
            name: currentSkinPack.name,
            public: currentSkinPack.public,
            fullPath: currentSkinPack.fullPath,
            url: currentSkinPack.url,
            submitter: currentSkinPack.submitter,
          };

        inputDataSkinsPack.subPacks = currentSkinPack.subPacks?.map(
          (subPack) => {
            return {
              subPack: subPack.subPack._id,
              order: subPack.order,
              _id: subPack._id as string,
              variation: currentVariations.includes(subPack.subPack.name),
            };
          }
        );
      }
    }

    if (
      JSON.stringify(currentTags.sort()) !== JSON.stringify(defaultTags.sort())
    ) {
      if (currentSkinPack) {
        if (!inputDataSkinsPack)
          inputDataSkinsPack = {
            _id: currentSkinPack._id,
            name: currentSkinPack.name,
            public: currentSkinPack.public,
            fullPath: currentSkinPack.fullPath,
            url: currentSkinPack.url,
            submitter: currentSkinPack.submitter,
          };

        inputDataSkinsPack.tags = currentTags.map((tag) => {
          return {
            name: tag.name,
            type: tag.type,
          };
        });
      }
    }

    if (skinPackOrder && skinPackOrder?.length > 0) {
      if (skinPackOrder && currentSkinPack) {
        if (
          !skinPackOrder.find(
            (skinPack) => skinPack.subPack._id === currentSkinPack._id
          )
        ) {
          if (!inputDataSkinsPack)
            inputDataSkinsPack = {
              _id: currentSkinPack._id,
              name: currentSkinPack.name,
              public: currentSkinPack.public,
              fullPath: currentSkinPack.fullPath,
              url: currentSkinPack.url,
              submitter: currentSkinPack.submitter,
            };

          inputDataSkinsPack.subPacks = skinPackOrder.map((skinPack) => {
            return {
              subPack: skinPack.subPack._id,
              order: skinPack.order,
              _id: skinPack._id as string,
              variation: currentVariations.includes(skinPack.subPack.name),
            };
          });
        }
      }
    }

    if (displayUpload) {
      setLoadingMessage("Uploading display image...");
      setLoading(true);

      let formData = new FormData();
      formData.append(
        "display",
        displayUpload.file,
        window.btoa(displayUpload.file.name)
      );

      axiosAuth
        .post(
          process.env.REACT_APP_BACKEND_URL +
            "/skinpack/" +
            currentSkinPack?._id +
            "/displayimage",
          formData
        )
        .then((response) => {
          setAlertsAndLoading(
            false,
            "",
            "Successfully uploaded display image.",
            "success"
          );
          setDisplayUpload(undefined);
        })
        .catch((error) => {
          setAlertsAndLoading(
            false,
            "",
            "Could not upload display image.",
            "error"
          );
        });
    }

    if (inputDataProfile) {
      if (Object.keys(inputDataProfile).length > 0) {
        setLoadingMessage("Updating profile...");
        setLoading(true);

        axiosAuth
          .patch(
            process.env.REACT_APP_BACKEND_URL + "/user/update",
            inputDataProfile
          )
          .then((response) => {
            setAlertsAndLoading(
              false,
              "",
              "Successfully updated profile.",
              "success"
            );
            setFormDataProfile(undefined);
            if (response.data.username) {
              setUserData({
                ...(userData as UserData),
                username: response.data.username,
              });
              setUsername(response.data.username);
            }
          })
          .catch((error) => {
            setAlertsAndLoading(
              false,
              "",
              "Could not update profile.",
              "error"
            );
          });
      }
    }

    if (inputDataSkinsPack) {
      if (inputDataSkinsPack?.allowedAccess) {
        inputDataSkinsPack.allowedAccess = inputDataSkinsPack.allowedAccess.map(
          (user: any) => user._id
        );
        inputDataSkinsPack.allowedAccess.push(userData?._id as string);
      }

      if (inputDataSkinsPack?.groupAllowedAccess) {
        inputDataSkinsPack.groupAllowedAccess =
          inputDataSkinsPack.groupAllowedAccess.map((group: any) => group._id);
      }

      if (Object.keys(inputDataSkinsPack).length > 0) {
        setLoadingMessage("Updating skin pack...");
        setLoading(true);

        axiosAuth
          .patch(
            process.env.REACT_APP_BACKEND_URL +
              "/skinpack/" +
              currentSkinPack?._id,
            inputDataSkinsPack
          )
          .then((response) => {
            setAlertsAndLoading(
              false,
              "",
              "Successfully updated skin pack.",
              "success"
            );
            window.location.reload();
          })
          .catch((error) => {
            setAlertsAndLoading(
              false,
              "",
              "Could not update skin pack.",
              "error"
            );
          });
      }
    }

    if (inputDataSkins && inputDataSkins.length > 0) {
      setLoadingMessage("Updating skin...");
      setLoading(true);

      axiosAuth
        .patch(process.env.REACT_APP_BACKEND_URL + "/skin", inputDataSkins)
        .then((response) => {
          setAlertsAndLoading(
            false,
            "",
            "Successfully updated skin.",
            "success"
          );
          window.location.reload();
        })
        .catch((error) => {
          setAlertsAndLoading(false, "", "Could not update skin.", "error");
        });
    }
  }

  function setAlertsAndLoading(
    loading: boolean,
    loadingMessage: string,
    alertMessage: string,
    alertType: string
  ) {
    setLoading(loading);
    setLoadingMessage(loadingMessage);
    setAlertMessage(alertMessage);
    setAlertType(alertType);
    setAlert(true);
  }

  function copyToClipboard(skin: Skin) {
    navigator.clipboard.writeText(skin.cdnUrl || skin.url);
    setAlertMessage(skin.name + " copied to clipboard.");
    setAlertType("success");
    setAlert(true);
  }

  async function handleSkinPackClick(skin: SkinPack) {
    if (formDataSkinPack || formDataSkins) {
      setAlertMessage("Please save changes before switching skin packs.");
      setAlertType("error");
      setAlert(true);
      return;
    }

    let newCurrentPack: SkinPack[] = [];

    skin = await getSkinPack(skin._id);

    if (currentSkinPacks.length > 1) {
      if (
        !currentSkinPacks[0].subPacks?.find(
          (subPack) => subPack.subPack === skin
        )
      ) {
        newCurrentPack = [skin];
      } else {
        newCurrentPack = [currentSkinPacks[0], skin];
      }
    } else {
      if (
        currentSkinPacks.length > 0 &&
        currentSkinPacks[0].subPacks?.find(
          (subPack) => subPack.subPack === skin
        )
      ) {
        newCurrentPack = [...currentSkinPacks, skin];
      } else {
        newCurrentPack = [skin];
      }
    }

    if (skin.subPacks && skin.subPacks?.length > 0) {
      setSkinPackOrder(
        skin.subPacks
          .sort((a: any, b: any) => a.order - b.order)
          .map((skinPack) => {
            return {
              subPack: skinPack.subPack,
              order: skinPack.order,
              _id: skinPack._id,
            };
          })
      );
    } else {
      setSkinPackOrder([]);
    }

    setEditPackColors(false);
    setPackColors(undefined);
    setFormDataSkinPack(undefined);
    setFormDataSkins(undefined);
    setDisplayUpload(undefined);
    setCurrentSkinPacks(newCurrentPack);
    setCurrentSkinPack(skin);
    setOldPack(JSON.parse(JSON.stringify(skin)));
    setDefaultVariations(
      (skin.subPacks
        ?.filter((subPack) => subPack.variation)
        .map((subPack) => subPack.subPack.name) as string[]) || []
    );
    setCurrentVariations(
      (skin.subPacks
        ?.filter((subPack) => subPack.variation)
        .map((subPack) => subPack.subPack.name) as string[]) || []
    );
    setDefaultTags(
      (skin.tags?.map((tag) => tag) as { name: string; type: string }[]) || []
    );
    setCurrentTags(
      (skin.tags?.map((tag) => tag) as { name: string; type: string }[]) || []
    );
  }

  function deleteSkinPack() {
    if (!admin) return;

    setLoadingMessage("Deleting skin pack...");
    setLoading(true);

    axiosAuth
      .delete(
        process.env.REACT_APP_BACKEND_URL + "/skinpack/" + currentSkinPack?._id
      )
      .then((response) => {
        setAlertMessage("Successfully deleted skin pack.");
        setAlertType("success");
        setAlert(true);
        setCurrentSkinPack(undefined);
        setOldPack(undefined);
        setCurrentSkinPacks([]);
        setDefaultVariations([]);
        setCurrentVariations([]);
        setDefaultTags([]);
        setCurrentTags([]);
        setSkinPackOrder([]);

        axiosAuth
          .get(process.env.REACT_APP_BACKEND_URL + "/user/me")
          .then((response) => {
            setLoading(false);
            setLoadingMessage("");
            setUserData(response.data);
            setUsername(response.data.username);
          })
          .catch((error) => {
            setLoading(false);
            setLoadingMessage("");
            setAlertMessage("Could not load profile.");
            setAlertType("error");
            setAlert(true);
          });
      })
      .catch((error) => {
        setLoading(false);
        setLoadingMessage("");
        setAlertMessage("Could not delete skin pack.");
        setAlertType("error");
        setAlert(true);
      });
  }

  function updateSkinOrder(
    skin: { skin: Skin; order: number; _id: string },
    direction: "up" | "down"
  ) {
    let sameSizeArray = currentSkinPack?.skins?.filter(
      (s) => s.skin.size === skin.skin.size
    );

    if (direction === "up") {
      if (sameSizeArray && currentSkinPack?.skins) {
        for (let i = 0; i < sameSizeArray.length; i++) {
          if (sameSizeArray[i]._id === skin._id) {
            let nextSkin = sameSizeArray[i + 1];
            if (nextSkin) {
              let newSkin = {
                ...nextSkin,
                order: skin.order,
              };
              let newNextSkin = {
                ...skin,
                order: nextSkin.order,
              };
              let newSkins = currentSkinPack.skins;
              newSkins[currentSkinPack.skins.indexOf(skin)] = newSkin;
              newSkins[currentSkinPack.skins.indexOf(nextSkin)] = newNextSkin;

              setCurrentSkinPack({
                ...currentSkinPack,
                skins: newSkins,
              });

              return;
            }
          }
        }
      }
    } else {
      if (sameSizeArray && currentSkinPack?.skins) {
        for (let i = 0; i < sameSizeArray.length; i++) {
          if (sameSizeArray[i]._id === skin._id) {
            let prevSkin = sameSizeArray[i - 1];
            if (prevSkin) {
              let newSkin = {
                ...prevSkin,
                order: skin.order,
              };
              let newPrevSkin = {
                ...skin,
                order: prevSkin.order,
              };
              let newSkins = currentSkinPack.skins;
              newSkins[currentSkinPack.skins.indexOf(skin)] = newSkin;
              newSkins[currentSkinPack.skins.indexOf(prevSkin)] = newPrevSkin;

              setCurrentSkinPack({
                ...currentSkinPack,
                skins: newSkins,
              });

              return;
            }
          }
        }
      }
    }
  }

  function updateSkinPackOrder(
    skinPack: { subPack: SkinPack; order: number; _id: string },
    direction: "up" | "down"
  ) {
    if (skinPackOrder) {
      if (direction === "up") {
        for (let i = 0; i < skinPackOrder.length; i++) {
          if (skinPackOrder[i].subPack._id === skinPack.subPack._id) {
            let nextSkinPack = skinPackOrder[i + 1];
            if (nextSkinPack) {
              let newSkinPacks = [...skinPackOrder];
              [newSkinPacks[i].order, newSkinPacks[i + 1].order] = [
                nextSkinPack.order,
                skinPack.order,
              ];

              newSkinPacks.sort((a: any, b: any) => a.order - b.order);

              setSkinPackOrder(newSkinPacks);
              return;
            }
          }
        }
      } else {
        for (let i = 0; i < skinPackOrder.length; i++) {
          if (skinPackOrder[i]._id === skinPack._id) {
            let prevSkinPack = skinPackOrder[i - 1];
            if (prevSkinPack) {
              let newSkinPacks = [...skinPackOrder];
              [newSkinPacks[i].order, newSkinPacks[i - 1].order] = [
                prevSkinPack.order,
                skinPack.order,
              ];

              setSkinPackOrder(
                newSkinPacks.sort((a: any, b: any) => a.order - b.order)
              );

              return;
            }
          }
        }
      }
    }
  }

  function deleteSkin() {
    if (!admin) return;
    setLoadingMessage("Deleting skin...");
    setLoading(true);

    axiosAuth
      .delete(process.env.REACT_APP_BACKEND_URL + "/skin/" + currentSkin?._id)
      .then((response) => {
        setAlertsAndLoading(false, "", "Successfully deleted skin.", "success");
        setCurrentSkinPack({
          ...currentSkinPack,
          skins: currentSkinPack?.skins?.filter(
            (skin) => skin.skin._id !== currentSkin?._id
          ),
        } as SkinPack);
        setOldPack({
          ...oldPack,
          skins: oldPack?.skins?.filter(
            (skin) => skin.skin._id !== currentSkin?._id
          ),
        } as SkinPack);
        setCurrentSkin(undefined);
      })
      .catch((error) => {
        setAlertsAndLoading(false, "", "Could not delete skin.", "error");
      });
  }

  function handleDisplayUpload(event: any) {
    if (event.target.files[0].size >= 25 * 1024 * 1024) {
      setAlertsAndLoading(false, "", "File too large.", "error");
      return;
    }

    let folder = currentSkinPack?.fullPath.split("/");
    folder?.shift();
    folder?.shift();

    let file = new File(
      [event.target.files[0]],
      folder?.join("/") + "/" + event.target.files[0].name
    );

    let fileReader = new FileReader();
    fileReader.onload = function () {
      setDisplayUpload({
        file: file,
        fileUrl: fileReader.result,
      });
    };
    fileReader.readAsDataURL(file);
  }

  function handleSubPackUpload(event: any) {
    const dt = new DataTransfer();
    for (const file of event.target.files) {
      if (file.size < 25 * 1024 * 1024) {
        dt.items.add(file);
      }
    }

    event.target.files = dt.files;
    if (event.target.files.length === 0) return;

    let formData = new FormData();
    let folder = currentSkinPack?.fullPath.split("/");
    folder?.shift();
    folder?.shift();
    for (let i = 0; i < event.target.files.length; i++) {
      formData.append(
        "subpacks",
        event.target.files[i],
        window.btoa(
          folder?.join("/") + "/" + event.target.files[i].webkitRelativePath
        )
      );
    }

    setLoadingMessage("Uploading sub packs...");
    setLoading(true);

    axiosAuth
      .post(
        process.env.REACT_APP_BACKEND_URL +
          "/skinpack/" +
          currentSkinPack?._id +
          "/subpacks",
        formData
      )
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        if (error.response.status === 409) {
          setAlertsAndLoading(false, "", "Sub pack already exists.", "error");
        } else {
          setAlertsAndLoading(false, "", "Could not upload sub pack.", "error");
        }
      });
  }

  function handleSkinsUpload(event: any) {
    const dt = new DataTransfer();
    for (const file of event.target.files) {
      if (file.size < 25 * 1024 * 1024) {
        dt.items.add(file);
      }
    }

    event.target.files = dt.files;
    if (event.target.files.length === 0) return;

    let formData = new FormData();
    let folder = currentSkinPack?.fullPath.split("/");
    folder?.shift();
    folder?.shift();
    for (let i = 0; i < event.target.files.length; i++) {
      formData.append(
        "skins",
        event.target.files[i],
        window.btoa(folder?.join("/") + "/" + event.target.files[i].name)
      );
    }

    setLoadingMessage("Uploading skins...");
    setLoading(true);

    axiosAuth
      .post(
        process.env.REACT_APP_BACKEND_URL +
          "/skinpack/" +
          currentSkinPack?._id +
          "/skins",
        formData
      )
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        setAlertsAndLoading(false, "", "Could not upload skins.", "error");
      });
  }

  function saveOrderPreset() {
    const presetName = prompt("Please enter a name for the order preset.");

    if (!presetName) return;

    let preset: {
      name: string;
      order: number;
    }[] =
      currentSkinPack?.skins?.map((skin) => {
        return {
          name: skin.skin.name,
          order: skin.order,
        };
      }) || [];

    let orderPreset: OrderPreset = {
      name: presetName,
      preset: preset,
    };

    setAlertsAndLoading(true, "Saving order preset...", "", "");

    axiosAuth
      .post(process.env.REACT_APP_BACKEND_URL + "/orderpreset", orderPreset)
      .then((response) => {
        setAlertsAndLoading(
          false,
          "",
          "Successfully saved order preset.",
          "success"
        );
      })
      .catch((error) => {
        setAlertsAndLoading(false, "", "Could not save order preset.", "error");
      });
  }

  function loadOrderPreset() {
    if (!selectedPreset) return;

    let newOrder: {
      _id: string;
      skin: Skin;
      order: number;
    }[] = [];

    let unusedSkins = currentSkinPack?.skins?.filter((skin) => {
      return !selectedPreset.preset.find(
        (preset) => preset.name === skin.skin.name
      );
    });

    selectedPreset.preset.forEach((preset) => {
      let skin = currentSkinPack?.skins?.find(
        (skin) => skin.skin.name === preset.name
      );

      if (skin) {
        newOrder.push({
          _id: skin._id,
          skin: skin.skin,
          order: preset.order,
        });
      }
    });

    newOrder.sort((a, b) => a.order - b.order);

    if (unusedSkins) {
      unusedSkins.forEach((skin) => {
        newOrder.push({
          _id: skin._id,
          skin: skin.skin,
          order: newOrder[newOrder.length - 1].order + 1,
        });
      });
    }

    setCurrentSkinPack((prevSkinPack) => ({
      ...prevSkinPack!,
      skins: newOrder,
    }));
    setAlertsAndLoading(
      false,
      "",
      "Successfully loaded order preset.",
      "success"
    );
  }

  async function getSkinPack(id: string): Promise<SkinPack> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosAuth.get(
          process.env.REACT_APP_BACKEND_URL + "/skin/" + id
        );
        resolve(response.data);
      } catch (error: any) {
        if (error.response.status === 401) {
          try {
            const response = await axiosAuth.get(
              process.env.REACT_APP_BACKEND_URL + "/skin/" + id,
              {
                headers: {
                  "x-access-token": "not-logged-in",
                },
              }
            );
            resolve(response.data);
          } catch (error) {
            setAlertMessage("Could not load skin pack.");
            setAlertType("error");
            setAlert(true);
          }
        } else {
          setAlertMessage("Could not load skin pack.");
          setAlertType("error");
          setAlert(true);
          reject();
        }
      }
    });
  }

  return (
    <form onSubmit={handleFormSubmit} style={{ width: "100%" }}>
      <Container maxWidth="xl">
        <Paper sx={{ p: 4 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              Account
            </Typography>
            <Box>
              {admin && (
                <>
                  <Button
                    variant="contained"
                    sx={{ height: 40, mr: 2 }}
                    type="button"
                    onClick={() => {
                      navigate("/groups");
                    }}
                  >
                    Manage Groups
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ height: 40, mr: 2 }}
                    type="button"
                    onClick={() => {
                      navigate("/admin");
                    }}
                  >
                    Manage Users
                  </Button>
                </>
              )}

              <Button
                variant="outlined"
                sx={{ height: 40, mr: 2 }}
                type="button"
                onClick={() => {
                  logoutUser();
                  navigate("/");
                }}
              >
                Logout
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", mt: 4 }}>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ mr: 16 }}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  Username
                </Typography>
                <Box sx={{ width: 270 }}>
                  {admin ? (
                    <TextField
                      fullWidth
                      name="username"
                      value={username}
                      onChange={(e) => {
                        setFormDataProfile({ username: e.target.value });
                        setUsername(e.target.value);
                      }}
                      required
                      margin="normal"
                      inputProps={{ sx: { boxShadow: "none !important" } }}
                    />
                  ) : (
                    <Typography variant="h6" sx={{ mt: 1 }}>
                      {username}
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box>
                {admin && (
                  <>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      Uploadlimit
                    </Typography>

                    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold", mr: 2 }}
                      >
                        {userData?.uploadCount
                          ? (userData?.uploadCount / 1_000_000).toFixed(0)
                          : 0}{" "}
                        MB
                      </Typography>
                      <ProgressBar
                        completed={
                          userData?.uploadCount
                            ? (userData?.uploadCount / 1_000_000).toFixed(0)
                            : 0
                        }
                        bgColor="#A63C46"
                        height="20px"
                        animateOnRender={true}
                        baseBgColor={"#2B2B2B"}
                        isLabelVisible={false}
                        width="250px"
                        transitionTimingFunction="ease-out"
                        maxCompleted={
                          userData?.uploadLimit
                            ? ((userData?.uploadLimit / 1_000_000).toFixed(
                                0
                              ) as any)
                            : 0
                        }
                      />
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold", ml: 2 }}
                      >
                        {userData?.uploadLimit
                          ? (userData?.uploadLimit / 1_000_000).toFixed(0)
                          : 0}{" "}
                        MB
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Box>

            {userData?.skinPacks && userData?.skinPacks.length > 0 && (
              <Box>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", mt: 4, mb: 2 }}
                >
                  Skin Packs
                </Typography>
                <Grid container spacing={2}>
                  {userData.skinPacks.map((skinPack) => (
                    <SkinPackCard
                      skinPack={skinPack}
                      isSelected={currentSkinPacks.find(
                        (sp) => sp._id === skinPack._id
                      )}
                      onClick={handleSkinPackClick}
                      key={skinPack._id}
                      editor={true}
                    />
                  ))}
                </Grid>
              </Box>
            )}
          </Box>
        </Paper>

        {skinPackOrder && skinPackOrder?.length > 0 && (
          <Paper sx={{ p: 4, mt: 5 }}>
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              Sub Packs
            </Typography>

            <Grid container spacing={2}>
              {skinPackOrder.map((skinPack) => (
                <SkinPackCard
                  skinPack={skinPack.subPack}
                  onClick={handleSkinPackClick}
                  isSelected={currentSkinPacks.find(
                    (sp) => sp._id === skinPack.subPack._id
                  )}
                  order={skinPack}
                  updateOrder={updateSkinPackOrder}
                  key={skinPack._id}
                  editor={true}
                />
              ))}
            </Grid>
          </Paper>
        )}

        {currentSkinPack && (
          <Paper sx={{ p: 4, mt: 5 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                Current Skin Pack
              </Typography>
              <Box>
                <label htmlFor="upload-subpack">
                  <Button
                    variant="contained"
                    sx={{ height: 40, mr: 2 }}
                    component="span"
                  >
                    Upload Sub Pack
                    <UploadIcon sx={{ ml: 1 }} />
                  </Button>
                  <input
                    id="upload-subpack"
                    hidden
                    accept="image/*,.webm"
                    type="file"
                    name="subpacks"
                    multiple
                    ref={(node) => {
                      inputSubPacks.current = node;

                      if (node) {
                        [
                          "webkitdirectory",
                          "directory",
                          "mozdirectory",
                        ].forEach((attr) => {
                          node.setAttribute(attr, "");
                        });
                      }
                    }}
                    onChange={handleSubPackUpload}
                  />
                </label>
                <label htmlFor="upload-skins">
                  <Button
                    variant="contained"
                    sx={{ height: 40 }}
                    component="span"
                  >
                    Upload Skin(s)
                    <UploadIcon sx={{ ml: 1 }} />
                  </Button>
                  <input
                    id="upload-skins"
                    hidden
                    accept="image/*,.webm"
                    type="file"
                    name="skins"
                    multiple
                    onChange={handleSkinsUpload}
                  />
                </label>
              </Box>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ width: 400 }}>
                    <Typography variant="h5" sx={{ fontWeight: "bold", mt: 4 }}>
                      Name
                    </Typography>
                    <TextField
                      fullWidth
                      name="skinpack-name"
                      value={currentSkinPack.name}
                      onChange={(e) => {
                        setCurrentSkinPack({
                          ...currentSkinPack,
                          name: e.target.value,
                        });
                      }}
                      required
                      margin="normal"
                    />

                    <Typography variant="h5" sx={{ fontWeight: "bold", mt: 4 }}>
                      Description
                    </Typography>
                    <TextField
                      fullWidth
                      name="skinpack-description"
                      value={currentSkinPack.description}
                      multiline
                      minRows={3}
                      onChange={(e) => {
                        setCurrentSkinPack({
                          ...currentSkinPack,
                          description: e.target.value,
                        });
                      }}
                      margin="normal"
                    />

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{ fontWeight: "bold", mt: 4, mb: 2 }}
                      >
                        Tags
                      </Typography>
                      <IconButton
                        onClick={() => {
                          const newTag = window.prompt("Enter new tag name");
                          if (newTag) {
                            const newTagType = window.prompt(
                              "Enter new tag type: Pack | Type | Color"
                            );
                            if (newTagType) {
                              setCurrentTags([
                                ...currentTags,
                                { name: newTag, type: newTagType },
                              ]);
                            }
                          }
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </Box>

                    <FormControl
                      variant="outlined"
                      sx={{ width: "100%", mb: 2 }}
                    >
                      <Select
                        fullWidth
                        name="tags"
                        value={currentTags}
                        onChange={(e: any) => {
                          setCurrentTags(
                            currentTags.filter(
                              (tag) => !e.target.value.includes(tag.name)
                            )
                          );
                        }}
                        multiple
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected.slice(0, 4).map((value) => (
                              <Chip label={`${value.name} (${value.type})`} />
                            ))}
                            {selected.length > 4 && (
                              <Chip label={`+${selected.length - 4} more`} />
                            )}
                          </Box>
                        )}
                      >
                        {currentTags.map((tag) => (
                          <MenuItem key={tag.name} value={tag.name}>
                            <Checkbox
                              checked={currentTags.includes(tag) || false}
                            />
                            <ListItemText
                              primary={tag.name}
                              secondary={tag.type}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <Typography variant="h5" sx={{ fontWeight: "bold", mt: 4 }}>
                      Access
                    </Typography>
                    <Box sx={{ display: "flex", mt: 1 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={currentSkinPack.public}
                            onChange={() =>
                              setCurrentSkinPack({
                                ...currentSkinPack,
                                public: !currentSkinPack.public,
                              })
                            }
                            name="public"
                            color="primary"
                          />
                        }
                        label="Public"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!currentSkinPack.public}
                            onChange={() =>
                              setCurrentSkinPack({
                                ...currentSkinPack,
                                public: !currentSkinPack.public,
                              })
                            }
                            name="public"
                            color="primary"
                          />
                        }
                        label="Private"
                      />
                    </Box>

                    {!currentSkinPack.public && (
                      <Box>
                        <Autocomplete
                          key={"allowed-access"}
                          multiple
                          id="allowed-access"
                          limitTags={2}
                          value={
                            currentSkinPack.allowedAccess?.filter(
                              (user) => user?._id !== userData?._id
                            ) as User[]
                          }
                          options={users}
                          sx={{ mt: 2 }}
                          getOptionLabel={(option) => option.username as string}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                checked={
                                  currentSkinPack.allowedAccess
                                    ?.map((user) => user?._id)
                                    ?.includes(option?._id) as boolean
                                }
                                sx={{ mr: 2 }}
                                color="primary"
                              />
                              {option?.username}
                            </li>
                          )}
                          isOptionEqualToValue={(option, value) =>
                            option._id === value._id
                          }
                          onChange={(event, value) => {
                            setCurrentSkinPack({
                              ...currentSkinPack,
                              allowedAccess: value as User[],
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Allowed Access"
                              placeholder="Allowed Access"
                            />
                          )}
                        />

                        <Autocomplete
                          key={"group-allowed-access"}
                          multiple
                          id="group-allowed-access"
                          limitTags={2}
                          value={currentSkinPack.groupAllowedAccess as Group[]}
                          options={groups}
                          sx={{ mt: 2 }}
                          getOptionLabel={(option) => option.name as string}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                checked={
                                  currentSkinPack.groupAllowedAccess
                                    ?.map((group) => group?._id)
                                    ?.includes(option?._id) as boolean
                                }
                                sx={{ mr: 2 }}
                                color="primary"
                              />
                              {option?.name}
                            </li>
                          )}
                          isOptionEqualToValue={(option, value) =>
                            option._id === value._id
                          }
                          onChange={(event, value) => {
                            setCurrentSkinPack({
                              ...currentSkinPack,
                              groupAllowedAccess: value as Group[],
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Group Allowed Access"
                              placeholder="Group Allowed Access"
                            />
                          )}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box
                  sx={{ width: 400, display: "flex", flexDirection: "column" }}
                >
                  <Box
                    sx={{
                      mb: 2,
                      mt: 4,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      Display Image
                    </Typography>
                    <label htmlFor="upload-image">
                      <Button
                        variant="contained"
                        sx={{ height: 40 }}
                        component="span"
                      >
                        Upload
                        <UploadIcon sx={{ ml: 1 }} />
                      </Button>
                      <input
                        id="upload-image"
                        hidden
                        accept="image/*,.webm"
                        type="file"
                        name="displayImageFile"
                        onChange={handleDisplayUpload}
                      />
                    </label>
                  </Box>
                  {displayUpload ? (
                    <Paper
                      elevation={2}
                      sx={{
                        display: "flex",
                        p: 1,
                        height: "100%",
                      }}
                    >
                      {displayUpload.file.name.split(".").pop() === "webm" ? (
                        <video
                          src={displayUpload.fileUrl}
                          style={{ width: "100%" }}
                          autoPlay
                          loop
                          muted
                        />
                      ) : (
                        <img
                          src={displayUpload.fileUrl}
                          alt="skin"
                          style={{ width: "100%" }}
                        />
                      )}
                    </Paper>
                  ) : (
                    <SkinCard
                      skin={currentSkinPack.displayImage}
                      onClick={copyToClipboard}
                      videoRef={videoRef}
                      editor={true}
                    />
                  )}
                  {currentSkinPack.subPacks &&
                    currentSkinPack.subPacks?.length > 0 && (
                      <>
                        <Typography
                          variant="h5"
                          sx={{ fontWeight: "bold", mb: 1, mt: 4 }}
                        >
                          Variations
                        </Typography>
                        <FormControl variant="outlined" sx={{ width: "100%" }}>
                          <InputLabel id="variations-label" sx={{ mt: 2 }}>
                            Variations
                          </InputLabel>
                          <Select
                            fullWidth
                            labelId="variations-label"
                            label="Variations"
                            name="variations"
                            value={currentVariations}
                            onChange={(event) =>
                              setCurrentVariations(
                                event.target.value as string[]
                              )
                            }
                            multiple
                            sx={{
                              mb: 2,
                              mt: 2,
                            }}
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                              </Box>
                            )}
                          >
                            {currentSkinPack.subPacks?.map((variation) => (
                              <MenuItem
                                key={variation._id}
                                value={variation.subPack.name}
                              >
                                <Checkbox
                                  checked={currentVariations.includes(
                                    variation.subPack.name
                                  )}
                                />
                                <ListItemText
                                  primary={variation.subPack.name}
                                />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </>
                    )}
                </Box>
              </Grid>
            </Grid>

            {currentSkinPack.skins && currentSkinPack.skins?.length > 0 && (
              <Box>
                <Box
                  sx={{
                    mt: 4,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Skins
                    </Typography>

                    <IconButton
                      sx={{ ml: 1 }}
                      onClick={() => setEditPackColors(!editPackColors)}
                    >
                      <BrushIcon />
                    </IconButton>

                    {editPackColors && (
                      <>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold", ml: 2 }}
                          >
                            Background Colors: #
                          </Typography>

                          <TextField
                            name="background-color"
                            value={packColors?.background}
                            onChange={(e) => {
                              setPackColors({
                                ...packColors,
                                background: e.target.value,
                              });
                            }}
                            margin="none"
                            inputProps={{
                              sx: { boxShadow: "none !important" },
                            }}
                            sx={{ width: 100, ml: 1 }}
                            size="small"
                          />
                        </Box>

                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold", ml: 4 }}
                          >
                            Icon Colors:
                          </Typography>

                          <FormGroup sx={{ ml: 2 }} row>
                            <RadioGroup
                              name="icon-colors"
                              value={packColors?.icon || ""}
                              onChange={(e) => {
                                setPackColors({
                                  ...packColors,
                                  icon: e.target.value,
                                });
                              }}
                              row
                              sx={{ ml: 1, height: 40 }}
                            >
                              <FormControlLabel
                                value="FFFFFF"
                                control={
                                  <Radio
                                    onClick={(e: any) => {
                                      if (e.target.value === packColors?.icon) {
                                        setPackColors({
                                          ...packColors,
                                          icon: "",
                                        });
                                      }
                                    }}
                                  />
                                }
                                label="Light #FFFFFF"
                              />
                              <FormControlLabel
                                value="212121"
                                control={
                                  <Radio
                                    onClick={(e: any) => {
                                      if (e.target.value === packColors?.icon) {
                                        setPackColors({
                                          ...packColors,
                                          icon: "",
                                        });
                                      }
                                    }}
                                  />
                                }
                                label="Dark #212121"
                              />
                            </RadioGroup>
                          </FormGroup>
                        </Box>
                      </>
                    )}
                  </Box>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Button
                      variant="contained"
                      type="button"
                      onClick={saveOrderPreset}
                    >
                      Save Preset
                    </Button>

                    <Button
                      variant="contained"
                      type="button"
                      onClick={() => {
                        setModalLoadPreset(true);

                        axiosAuth
                          .get(
                            process.env.REACT_APP_BACKEND_URL + "/orderpreset"
                          )
                          .then((response) => {
                            setOrderPresets(response.data);
                          })
                          .catch((error) => {
                            setAlertsAndLoading(
                              false,
                              "",
                              "Could not load order presets.",
                              "error"
                            );
                          });
                      }}
                    >
                      Load Preset
                    </Button>
                  </Box>
                </Box>

                {["1x1", "2x1", "4x1", "6x1", "Other"].map(
                  (size: any) =>
                    currentSkinPack.skins &&
                    currentSkinPack.skins.filter(
                      (s: any) =>
                        s.skin.size === size ||
                        (size === "Other" &&
                          s.skin.size !== "1x1" &&
                          s.skin.size !== "2x1" &&
                          s.skin.size !== "4x1" &&
                          s.skin.size !== "6x1")
                    ).length > 0 && (
                      <>
                        <Box
                          sx={{
                            mt: 3,
                            mb: 2,
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Divider
                            sx={{
                              mr: 1.8,
                              bgcolor: "#A63C46",
                              flex: 1,
                              height: 2,
                            }}
                          />
                          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                            {size}
                          </Typography>
                          <Divider
                            sx={{
                              ml: 2,
                              bgcolor: "#A63C46",
                              flex: 50,
                              height: 2,
                            }}
                          />
                        </Box>

                        <Grid container spacing={2} key={size.slice(1)}>
                          {size !== "Other"
                            ? currentSkinPack.skins
                                ?.filter((s: any) => s.skin.size === size)
                                ?.sort((a: any, b: any) => a.order - b.order)
                                .map((skin) => (
                                  <Grid
                                    item
                                    xs={12 / (size === "1x1" ? 6 : 4)}
                                    key={skin._id}
                                  >
                                    <SkinCard
                                      skin={skin.skin}
                                      videoRef={videoRef}
                                      order={skin}
                                      updateOrder={updateSkinOrder}
                                      onContextMenu={(e: any, skin: any) => {
                                        setCurrentSkin(skin);
                                        setClicked(true);
                                        if (window.innerWidth - e.pageX < 260)
                                          e.pageX -= 240;

                                        e.pageY = e.pageY - window.scrollY;
                                        if (e.pageY > window.innerHeight - 300)
                                          e.pageY -= 300;

                                        setPoints({
                                          x: e.pageX,
                                          y: e.pageY,
                                        });
                                      }}
                                      editor={true}
                                    />
                                  </Grid>
                                ))
                            : currentSkinPack.skins
                                ?.filter(
                                  (s: any) =>
                                    s.skin.size !== "1x1" &&
                                    s.skin.size !== "2x1" &&
                                    s.skin.size !== "4x1" &&
                                    s.skin.size !== "6x1"
                                )
                                .sort((a: any, b: any) => a.order - b.order)
                                .map((skin: any) => (
                                  <Grid item xs={2} key={skin._id}>
                                    <SkinCard
                                      skin={skin.skin}
                                      updateOrder={updateSkinOrder}
                                      order={skin}
                                      videoRef={videoRef}
                                      onContextMenu={(e: any, skin: any) => {
                                        setCurrentSkin(skin);
                                        setClicked(true);
                                        if (window.innerWidth - e.pageX < 260)
                                          e.pageX -= 240;

                                        e.pageY = e.pageY - window.scrollY;
                                        if (e.pageY > window.innerHeight - 300)
                                          e.pageY -= 300;

                                        setPoints({
                                          x: e.pageX,
                                          y: e.pageY,
                                        });
                                      }}
                                      editor={true}
                                    />
                                  </Grid>
                                ))}
                        </Grid>
                      </>
                    )
                )}
              </Box>
            )}

            <Box sx={{ display: "flex", mt: 4 }}>
              <Button
                variant="outlined"
                type="button"
                onClick={() => setDeleteItem("skinpack")}
              >
                Delete
              </Button>
            </Box>
          </Paper>
        )}
      </Container>

      <Button
        variant="contained"
        type="submit"
        sx={{
          position: "fixed",
          width: 50,
          height: 65,
          bottom: 30,
          right: 36,
          borderRadius: "10%",
          display: "flex",
        }}
      >
        <SaveIcon sx={{ fontSize: 32 }} />
      </Button>

      {clicked && (
        <Box
          sx={{
            position: "fixed",
            top: points.y,
            left:
              points.x && subMenu && points.x > window.innerWidth - 600
                ? points.x - 300
                : points.x,
            display: "flex",
            flexDirection:
              points.x > window.innerWidth - 600 ? "row-reverse" : "row",
          }}
        >
          <Paper sx={{ width: 240, maxWidth: 500 }} elevation={6}>
            <MenuList>
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  setSubMenu("name");
                }}
                key={"Change Name"}
                sx={{ p: 1 }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
                    <EditIcon />
                  </Box>
                  Change Name
                </Box>
              </MenuItem>

              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  setSubMenu("size");
                }}
                key={"Change Size"}
                sx={{ p: 1 }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
                    <SizesIcon width={24} />
                  </Box>
                  Change Size
                </Box>
              </MenuItem>

              <MenuItem
                onClick={(e) => {
                  setDisplayUpload(undefined);
                  setCurrentSkinPack({
                    ...currentSkinPack,
                    displayImage: currentSkin,
                  } as SkinPack);
                }}
                key={"Set Display Image"}
                sx={{ p: 1 }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
                    <ImageIcon />
                  </Box>
                  Set as Display Image
                </Box>
              </MenuItem>

              <MenuItem
                onClick={(e) => {
                  let newSkin = {
                    ...currentSkin,
                    display: !currentSkin?.display,
                  } as Skin;
                  setCurrentSkin(newSkin);
                }}
                key={"Set Display"}
                sx={{ p: 1 }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
                    {currentSkin?.display ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </Box>
                  {currentSkin?.display ? "Show in Pack" : "Don't Show in Pack"}
                </Box>
              </MenuItem>

              <MenuItem
                onClick={(e) => {
                  let newSkin = {
                    ...currentSkin,
                    transparent: !currentSkin?.transparent,
                  } as Skin;
                  setCurrentSkin(newSkin);
                }}
                key={"Set Transparent"}
                sx={{ p: 1 }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
                    <OverlayIcon width={20} />
                  </Box>
                  {currentSkin?.transparent
                    ? "Remove Transparency"
                    : "Make Transparent"}
                </Box>
              </MenuItem>

              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  setSubMenu("background");
                }}
                key={"Set Background Color"}
                sx={{ p: 1 }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
                    <BrushIcon />
                  </Box>
                  Set Background Color
                </Box>
              </MenuItem>

              <Divider />

              <MenuItem
                onClick={(e) => {
                  copyToClipboard(currentSkin as Skin);
                }}
                key={"Copy"}
                sx={{ p: 1 }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
                    <ContentCopyIcon />
                  </Box>
                  Copy
                </Box>
              </MenuItem>

              <MenuItem
                onClick={(e) => {
                  setDeleteItem("skin");
                }}
                key={"Delete"}
                sx={{ p: 1 }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
                    <DeleteIcon />
                  </Box>
                  Delete
                </Box>
              </MenuItem>
            </MenuList>
          </Paper>

          {subMenu && subMenu === "background" && (
            <Box sx={{ width: 300 }}>
              <Paper
                sx={{ display: "flex", flex: 1 }}
                elevation={4}
                onClick={(e) => e.stopPropagation()}
              >
                <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
                  <Typography variant="subtitle1" sx={{ mr: 1 }}>
                    #
                  </Typography>
                  <TextField
                    name="color"
                    value={currentSkin?.backgroundColor}
                    onChange={(e) => {
                      setCurrentSkin({
                        ...currentSkin,
                        backgroundColor: e.target.value,
                      } as Skin);
                    }}
                    margin="normal"
                    size="small"
                    sx={{ width: 100 }}
                  />
                </Box>
              </Paper>
            </Box>
          )}

          {subMenu && subMenu === "name" && (
            <Box sx={{ width: 300 }}>
              <Paper
                sx={{ display: "flex", flex: 1 }}
                elevation={4}
                onClick={(e) => e.stopPropagation()}
              >
                <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
                  <Typography variant="subtitle1" sx={{ mr: 1 }}>
                    Name:
                  </Typography>
                  <TextField
                    name="name"
                    value={currentSkin?.name}
                    onChange={(e) => {
                      setCurrentSkin({
                        ...currentSkin,
                        name: e.target.value,
                      } as Skin);
                    }}
                    margin="normal"
                    size="small"
                    sx={{ width: 200 }}
                  />
                </Box>
              </Paper>
            </Box>
          )}

          {subMenu && subMenu === "size" && (
            <Box>
              <Paper
                sx={{ display: "flex", flex: 1 }}
                elevation={4}
                onClick={(e) => e.stopPropagation()}
              >
                <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
                  <Typography variant="subtitle1" sx={{ mr: 1 }}>
                    Size:
                  </Typography>
                  <TextField
                    name="name"
                    value={currentSkin?.size}
                    onChange={(e) => {
                      setCurrentSkin({
                        ...currentSkin,
                        size: e.target.value,
                      } as Skin);
                    }}
                    margin="normal"
                    size="small"
                    sx={{ width: 100 }}
                  />
                </Box>
              </Paper>
            </Box>
          )}
        </Box>
      )}

      {deleteItem && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "fixed",
            top: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0,0,0,0.5)",
            zIndex: 10000,
          }}
        >
          <Paper
            sx={{
              width: 400,
              height: 150,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              p: 2,
            }}
            elevation={1}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              Are you sure you want to delete this {deleteItem}?
            </Typography>
            <Box>
              <Button
                variant="outlined"
                sx={{ mt: 2, mr: 2 }}
                onClick={() => {
                  if (deleteItem === "skin") {
                    deleteSkin();
                  } else {
                    deleteSkinPack();
                  }
                  setDeleteItem("");
                }}
              >
                Yes
              </Button>
              <Button
                variant="outlined"
                sx={{ mt: 2 }}
                onClick={() => setDeleteItem("")}
              >
                No
              </Button>
            </Box>
          </Paper>
        </Box>
      )}

      {/* modal */}
      <Modal open={modalLoadPreset} onClose={() => setModalLoadPreset(false)}>
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            p: 4,
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Load Preset
          </Typography>
          <Box sx={{ mt: 2 }}>
            <FormControl variant="outlined" sx={{ width: "100%", mb: 2 }}>
              <Autocomplete
                key={"order-preset"}
                id="order-preset"
                options={orderPresets}
                getOptionLabel={(option) => option.name}
                sx={{ width: "100%" }}
                onChange={(event, value) => {
                  setSelectedPreset(value as OrderPreset);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Order Preset"
                    placeholder="Order Preset"
                  />
                )}
              />
            </FormControl>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                type="button"
                onClick={() => {
                  setModalLoadPreset(false);
                  loadOrderPreset();
                }}
              >
                Load
              </Button>
            </Box>
          </Box>
        </Paper>
      </Modal>
    </form>
  );
}
