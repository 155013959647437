import { Box, Paper, TextField } from "@mui/material";
import useDisplaySize from "../../hooks/useDisplaySize";
import { Search as SearchIcon } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { axiosAuth } from "../../interceptors";
import { Tag, TagType } from "../../utils/interfaces";

export default function SearchBox(props: any) {
  const matches = useDisplaySize();

  const [isFocused, setIsFocused] = useState(false);

  const { inputFilters, setInputFilters, onTagFilter } = props;

  const tagTypes = [
    {
      name: "Pack",
      examples: ["Backgrounds", "Overlays", "Set"],
    },
    {
      name: "Type",
      examples: ["Animated", "Static"],
    },
    {
      name: "Color",
      examples: ["Blue", "Red", "White"],
    },
  ];
  const [tags, setTags] = useState<Tag[]>();

  const [possibleTagTypes, setPossibleTagTypes] = useState<
    {
      name: string;
      examples: string[];
    }[]
  >(tagTypes);

  const [selectedTagType, setSelectedTagType] = useState<{
    name: string;
    examples: string[];
  }>();

  const [filterTagType, setFilterTagType] = useState<TagType>();

  const [possibleTags, setPossibleTags] = useState<Tag[]>([]);
  const [filteredPossibleTags, setFilteredPossibleTags] = useState<Tag[]>([]);
  const [selectedTag, setSelectedTag] = useState<Tag>();

  const [filterTag, setFilterTag] = useState<Tag>();

  useEffect(() => {
    axiosAuth
      .get(process.env.REACT_APP_BACKEND_URL + "/tag")
      .then((res) => {
        setTags(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    function handleKeyDown(e: any) {
      if (
        !filterTagType &&
        (!possibleTagTypes || possibleTagTypes.length === 0)
      )
        return;

      if (e.key === "ArrowUp") {
        if (!filterTagType) {
          possibleTagTypes.forEach((type, index) => {
            if (type === selectedTagType) {
              if (index === 0) return;
              setSelectedTagType(possibleTagTypes[index - 1]);
            }
          });
        } else {
          possibleTags.forEach((tag, index) => {
            if (tag === selectedTag) {
              if (index === 0) return;
              setSelectedTag(possibleTags[index - 1]);
            }
          });
        }
      } else if (e.key === "ArrowDown") {
        if (!filterTagType) {
          if (!selectedTagType) {
            setSelectedTagType(possibleTagTypes[0] || undefined);
          } else {
            possibleTagTypes.forEach((type, index) => {
              if (type === selectedTagType) {
                if (index === possibleTagTypes.length - 1) return;
                setSelectedTagType(possibleTagTypes[index + 1]);
              }
            });
          }
        } else {
          if (!selectedTag) {
            setSelectedTag(possibleTags[0] || undefined);
          } else {
            possibleTags.forEach((tag, index) => {
              if (tag === selectedTag) {
                if (index === possibleTags.length - 1) return;
                setSelectedTag(possibleTags[index + 1]);
              }
            });
          }
        }
      } else if (e.key === "Enter") {
        if (!filterTagType) {
          if (selectedTagType) {
            setFilterTagType(selectedTagType);
            setInputFilters({
              ...inputFilters,
              skinpacks: "",
            });
            setPossibleTagTypes([]);
            setPossibleTags(
              tags?.filter((tag) => tag.type === selectedTagType.name) || []
            );
            setFilteredPossibleTags(
              tags?.filter((tag) => tag.type === selectedTagType.name) || []
            );
          }
        } else {
          if (selectedTag) {
            setFilterTag(selectedTag);
            setInputFilters({
              ...inputFilters,
              skinpacks: "",
            });
            setPossibleTags([]);
            setFilteredPossibleTags([]);
          }
        }
      } else if (
        e.key === "Escape" ||
        (e.key === "Backspace" && !inputFilters.skinpacks)
      ) {
        if (!filterTag) {
          setFilterTagType(undefined);
          setSelectedTagType(undefined);
          setPossibleTagTypes(tagTypes);
        }

        setFilterTag(undefined);
        setSelectedTag(undefined);
        setPossibleTags(
          tags?.filter((tag) => tag.type === filterTagType?.name) || []
        );
        setFilteredPossibleTags(
          tags?.filter((tag) => tag.type === filterTagType?.name) || []
        );
      }
    }

    isFocused && document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    isFocused,
    selectedTagType,
    possibleTagTypes,
    possibleTags,
    selectedTag,
    inputFilters.skinpacks,
  ]);


  useEffect(() => {
    onTagFilter(filterTag, filterTagType?.name);
  }, [filterTag, filterTagType, onTagFilter]);

  function onSearch(e: any) {
    setInputFilters({
      ...inputFilters,
      skinpacks: e.target.value,
    });

    if (!filterTagType) {
      let validList: { name: string; examples: string[] }[] = tagTypes;

      if (e.target.value !== "") {
        validList = [];

        tagTypes.forEach((type) => {
          if (
            type.name.toLowerCase().includes(e.target.value.toLowerCase())
          ) {
            validList.push(type);
          } else if (
            !type.name.toLowerCase().includes(e.target.value.toLowerCase()) &&
            possibleTagTypes.includes(type)
          ) {
            validList = validList.filter((tag) => tag !== type);
          }
        });

        setPossibleTagTypes(validList);
      } else {
        setPossibleTagTypes(validList);
      }

      if (
        validList.length > 0 &&
        (!selectedTagType || !validList.includes(selectedTagType))
      ) {
        setSelectedTagType(validList[0]);
      }
    } else {
      let validList: Tag[] = possibleTags || [];

      if (e.target.value !== "") {
        validList = [];

        possibleTags?.forEach((tag) => {
          if (
            tag.name.toLowerCase().includes(e.target.value.toLowerCase())
          ) {
            validList.push(tag);
          } else if (
            !tag.name.toLowerCase().includes(e.target.value.toLowerCase()) &&
            filteredPossibleTags.includes(tag)
          ) {
            validList = validList.filter((t) => t !== tag);
          }
        });

        setFilteredPossibleTags(validList);
      } else {
        setFilteredPossibleTags(validList);
      }

      if (validList.length > 0 && (!selectedTag || !validList.includes(selectedTag))) {
        setSelectedTag(validList[0]);
      }
    }
  }

  return (
    <Box>
      <TextField
        label={"Skinpacks"}
        placeholder={"Search"}
        value={inputFilters.skinpacks}
        onChange={onSearch}
        size="small"
        sx={{ mr: 2, width: matches["990"] ? 150 : 250, mt: 2 }}
        InputProps={{
          endAdornment: <SearchIcon />,
          startAdornment: filterTagType && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 30,
                  borderRadius: 1,
                  color: "text.secondary",
                  mr: 1,
                  fontSize: "0.8rem",
                }}
              >
                {filterTagType.name}:
              </Box>
              {filterTag && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 30,
                    borderRadius: 1,
                    color: "text.secondary",
                    mr: 1,
                    fontSize: "0.8rem",
                  }}
                >
                  {filterTag.name}
                </Box>
              )}
            </Box>
          ),
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />

      {isFocused &&
        (possibleTagTypes.length > 0 || possibleTags.length > 0) && (
          <Paper
            sx={{
              display: "flex",
              position: "absolute",
              flexDirection: "column",
              top: 66,
              padding: 1,
              width: matches["990"] ? 150 : 250,
              zIndex: 1000,
            }}
          >
            {possibleTagTypes &&
              possibleTagTypes.length > 0 &&
              possibleTagTypes.map((type) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mr: 1,
                    p: 0.5,
                    borderRadius: 1,
                    backgroundColor:
                      selectedTagType === type ? "#282828" : "transparent",
                  }}
                >
                  <Box sx={{ fontWeight: "bold", fontSize: "0.8rem", mr: 1 }}>
                    {type.name}
                  </Box>
                  <Box
                    sx={{
                      fontSize: "0.7rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      fontStyle: "italic",
                    }}
                  >
                    {type.examples.join(", ")}
                    {type.name === "Color" && "..."}
                  </Box>
                </Box>
              ))}

            {filteredPossibleTags &&
              filteredPossibleTags.length > 0 &&
              filteredPossibleTags
                .filter((tag) => tag.type === filterTagType?.name)
                .map((tag) => (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mr: 1,
                      p: 0.5,
                      borderRadius: 1,
                      backgroundColor:
                        selectedTag === tag ? "#282828" : "transparent",
                    }}
                  >
                    <Box sx={{ fontWeight: "bold", fontSize: "0.8rem", mr: 1 }}>
                      {tag.name}
                    </Box>
                  </Box>
                ))}
          </Paper>
        )}
    </Box>
  );
}
