import { useMediaQuery } from "@mui/material";

const useDisplaySize = () => {
  // make a function that has a list of sizes and checks if the window is that size, then returns the size and on update window size updates the size with useMediaQuery
  const matches = {
    xs: useMediaQuery("(max-width: 600px)"),
    sm: useMediaQuery("(max-width: 960px)"),
    md: useMediaQuery("(max-width: 1280px)"),
    lg: useMediaQuery("(max-width: 1920px)"),
    xl: useMediaQuery("(min-width: 1920px)"),

    1420: useMediaQuery("(max-width: 1420px)"),
    1400: useMediaQuery("(max-width: 1400px)"),
    1300: useMediaQuery("(max-width: 1300px)"),
    1150: useMediaQuery("(max-width: 1150px)"),
    1080: useMediaQuery("(max-width: 1080px)"),
    1025: useMediaQuery("(max-width: 1025px)"),
    990: useMediaQuery("(max-width: 990px)"),
    920: useMediaQuery("(max-width: 920px)"),
    880: useMediaQuery("(max-width: 880px)"),
  };

  return matches;
};

export default useDisplaySize;
