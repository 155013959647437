import { Box, Paper, Typography } from "@mui/material";
import {
  ArrowBackIosNew as ArrowBackIosNewIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  PlayCircle as PlayCircleIcon,
  VisibilityOff as VisibilityOffIcon,
  WarningAmber as WarningAmberIcon,
} from "@mui/icons-material";
import { useState, useEffect, useRef } from "react";
import { ReactComponent as OverlayIcon } from "../assets/OverlayIcon.svg";
import { ReactComponent as OverlayIconDark } from "../assets/OverlayIconDark.svg";
import useDisplaySize from "../hooks/useDisplaySize";

export function SkinCard(this: any, props: any) {
  const {
    skin,
    currentSkin,
    onClick = () => {},
    videoRef = [],
    animate = false,
    order = null,
    updateOrder = () => {},
    onContextMenu = () => {},
    editor = false,
  } = props;

  const [icons, setIcons] = useState([]);

  const matches = useDisplaySize();

  const [error, setError] = useState(false);

  async function checkImage(url: string) {
    const res = await fetch(url, { method: "HEAD" });
    const blob = await res.blob();

    return blob.type.startsWith("image/");
  }

  useEffect(() => {
    if (skin && editor) {
      if (skin.url.split(".").pop() !== "webm") {
        checkImage(skin.cdnUrl).then((res) => {
          if (!res) {
            setError(true);
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    if (skin) {
      let iconColor = "#FFFFFF";
      if (skin.iconColor) {
        iconColor = "#" + skin.iconColor;
      } else {
        let color = skin.backgroundColor;
        if (color.length === 3) {
          color = color.split("").map((c: string) => c + c).join("");
        }
  
        let rgb = parseInt(color, 16);
        let r = (rgb >> 16) & 0xff;
        let g = (rgb >> 8) & 0xff;
        let b = (rgb >> 0) & 0xff;
  
        
        let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  
        if (luma > 128) {
          iconColor = "#212121";
        }
      }

      const icons = [];
      if (skin.fileName.split(".").pop() === "webm") {
        icons.push(<PlayCircleIcon key="play" sx={{color: iconColor}} />);
      }
      if (skin.display) {
        icons.push(<VisibilityOffIcon key="visibility" sx={{color: iconColor}} />);
      }
      if (skin.transparent) {
        icons.push(iconColor === "#212121" ? <OverlayIconDark key="overlay" width={20} style={{marginTop: 2}} /> : <OverlayIcon key="overlay" width={20} style={{marginTop: 2}} />);
      }
      setIcons(icons as any);

    }
  }, [skin]);

  const videoEl = useRef();

  const [hover, setHover] = useState(false);

  function onPaperHover() {
    setHover(!hover);
    if (animate) return;
    if (videoEl.current) {
      if (hover) {
        (videoEl.current as any).pause();
      } else {
        (videoEl.current as any).play();
      }
    }
  }

  return (
    skin && (
      <Paper
        elevation={2}
        sx={{
          display: "flex",
          p: matches['md'] ? 0.5 : 1,
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          background: currentSkin === skin ? "#A63C46" : "",
          cursor: order && !onContextMenu ? "default" : "pointer",
        }}
        onClick={() => onClick(skin)}
        onMouseEnter={onPaperHover}
        onMouseLeave={onPaperHover}
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onContextMenu(e, skin);
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              position: "relative",
              background: skin.backgroundColor
                ? "#" + skin.backgroundColor
                : "white",
              display: "flex",
              justifyContent: "flex-end",
              height: "100%",
              width: "100%",
            }}
          >
            {skin.url.split(".").pop() === "webm" ? (
              <video
                disableRemotePlayback
                disablePictureInPicture
                ref={(el) => {
                  if (videoRef) videoRef.current.push(el as any);
                  videoEl.current = el as any;
                }}
                src={skin.cdnUrl}
                style={{ width: "100%" }}
                loop
                muted
                {...(animate || hover) && {
                  autoPlay: true,
                }}
                draggable={false}
                onError={({currentTarget}) => {
                  currentTarget.onerror = null;
                  let size = skin.size ? skin.size.split("x") : [2, 1];
                  size = `${size[0] * 100}x${size[1] * 100}`;
                  currentTarget.src = `https://placehold.co/${size}.mp4?text=${skin.name}&font=montserrat`;
                }}
              />
            ) : (
              <img
                src={skin.resizedUrl || skin.cdnUrl}
                alt={skin.name}
                style={{ width: "100%" }}
                draggable={false}
                onError={({currentTarget}) => {
                  currentTarget.onerror = null;
                  let size = skin.size ? skin.size.split("x") : [2, 1];
                  size = `${size[0] * 100}x${size[1] * 100}`;
                  currentTarget.src = `https://placehold.co/${size}/000000/FFFFFF/png?text=${skin.name}&font=montserrat`;
                }}
              />
            )}
            {order && order.order !== null && (
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <ArrowBackIosNewIcon
                  sx={{
                    cursor: "pointer",
                    height: "100%",
                    width: "25%",
                    color: "#A63C46",
                  }}
                  onClick={() => updateOrder(order, "down")}
                />
                <ArrowForwardIosIcon
                  sx={{
                    cursor: "pointer",
                    height: "100%",
                    width: "25%",
                    color: "#A63C46",
                  }}
                  onClick={() => updateOrder(order, "up")}
                />
              </Box>
            )}
            {icons.map((icon, i) => (
              <Box
                key={i}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: i * 32,
                  m: 1,
                  color: "#D9D9D9",
                }}
              >
                {icon}
              </Box>
            ))}

            {error && (
              <Box
                sx={{
                  position: "absolute",
                  top: "5%",
                  left: "5%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  
                }}
              >
                <WarningAmberIcon sx={{ color: "#000", position: "absolute", top: 2, left: -2 }} />
                <WarningAmberIcon sx={{ color: "#A63C46", zIndex: 10 }} />
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            position: "relative",
            display: hover ? "flex" : "none",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              mt: 1,
              color: "#D9D9D9",
              overflow: "hidden",
              textOverflow: "ellipsis",
              position: "absolute",
              bottom: "5%",
              whiteSpace: "nowrap",
              width: "100%",
              textAlign: "center",
              padding: "0 5px",
              background: "rgba(0,0,0,0.5)",
            }}
          >
            {skin.name}
          </Typography>
        </Box>
      </Paper>
    )
  );
}
