import {
  Button,
  Box,
  Tooltip,
  Switch,
  Typography,
  Select,
  MenuItem,
  Paper,
} from "@mui/material";
import { useState } from "react";
import { ReactComponent as SizesIcon } from "../../assets/SizeIcon.svg";
import { ReactComponent as GridIcon } from "../../assets/GridIcon.svg";
import useDisplaySize from "../../hooks/useDisplaySize";

export function Filterbar(props: any) {
  const {
    gridWidth,
    setGridWidth,
    sizeFilter,
    setSizeFilter,
    setAnimate,
    packSizes = [],
    variationList = [],
    currentVariation = {},
    handleVariationChange = () => {},
  } = props;

  const matches = useDisplaySize();

  const [gridOptions] = useState(['S', 'M', 'L']);
  const [sizesOptions] = useState(["1x1", "2x1", "4x1", "6x1", "Other"]);

  const [animateVar, setAnimateVar] = useState(false);

  function handleSizeFilter(e: any) {
    if (sizeFilter.includes(e)) {
      setSizeFilter(sizeFilter.filter((size: string) => size !== e));
    } else {
      setSizeFilter([...sizeFilter, e]);
    }
  }

  function handleAnimate() {
    setAnimateVar(!animateVar);
    setAnimate(!animateVar);
  }

  return (
    <Paper sx={{ display: "flex", justifyContent: "space-between", position: "sticky", top: 0, zIndex: 10000, py:2, flexWrap: 'wrap', }} elevation={0.75}>
      <Box sx={{ display: "flex", p: 1, alignItems: "center", height: 46 }}>
        {!matches['1300'] && (
        <Typography variant="h5" sx={{ fontWeight: "bold", mr: 2 }}>
          {variationList.length > 1 ? "Variations" : "Skins"}
        </Typography>
        )}

        {variationList.length > 1 && (
          <Select
            value={currentVariation}
            renderValue={(value: any) => value.name}
            sx={{ mr: 2, height: 46, textOverflow: 'ellipsis', maxWidth: 150,
              backgroundColor: "#A63C46",
              color: "white",
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: '#A63C46',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#A63C46',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#A63C46',
              },
              '&.Mui-focused': {
                backgroundColor: "transparent",
              },
              }}
            onChange={handleVariationChange}
          >
            {variationList.map((variation: any) => (
              <MenuItem key={variation._id} value={variation}>
                {variation.name}
              </MenuItem>
            ))}
          </Select>
        )}

        <Box sx={{ display: "flex", alignItems: "center", ml: matches['1300'] ? 0 : 3 }}>
          <Tooltip
            title="Grid Size"
            placement="top"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "primary.main",
                  "& .MuiTooltip-arrow": {
                    color: "primary.main",
                  },
                },
              },
            }}
          >
            <GridIcon width={20} style={{marginRight: 16}} />
          </Tooltip>

          {gridOptions.filter((option) => !matches['1080'] || option !== 'L').map((option: string) => (
            <Button
              variant={gridWidth === option ? "contained" : "outlined"}
              onClick={() => setGridWidth(option)}
              sx={{ mr: 1, minHeight: 0, minWidth: 0, py: 1, px: (matches['1420'] && variationList.length > 0) ? 2 : matches['1025'] ? 2 : 3 }}
            >
              {option}
            </Button>
          ))}
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", ml: 3, mr: 2 }}>
          <Tooltip
            title="Sizes"
            placement="top"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "primary.main",
                  "& .MuiTooltip-arrow": {
                    color: "primary.main",
                  },
                },
              },
            }}
          >
            <SizesIcon width={28} style={{marginRight: 16}} />
          </Tooltip>

          {sizesOptions.filter((size) => !matches['1150'] || packSizes.includes(size)).map((option: string) => (
            <Button
              variant={sizeFilter.includes(option) ? "contained" : "outlined"}
              key={option}
              onClick={() => handleSizeFilter(option)}
              sx={{ mr: 1, minHeight: 0, minWidth: 0, py: 1, px: (matches['1420'] && variationList.length > 0) ? 2 : matches['1025'] ? 2 : 3 }}
              disabled={!packSizes.includes(option)}
            >
              {option}
            </Button>
          ))}
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {!matches['920'] && (
          <Typography sx={{ mr: 1 }}>Autoplay</Typography>
        )}
        <Switch
          checked={animateVar}
          onChange={handleAnimate}
          color="primary"
          inputProps={{ "aria-label": "controlled" }}
        />
      </Box>
    </Paper>
  );
}
