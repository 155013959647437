/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect, useContext, useRef, useCallback } from "react";
import {
  Paper,
  Grid,
  Typography,
  Box,
  Button,
  Pagination,
  TextField,
  Container,
  Divider,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { Filterbar } from "../components/Home/Filterbar";
import { AppContext } from "../context/appContext";
import { Link } from "react-router-dom";
import {
  Upload as UploadIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { SkinPackCard } from "../components/SkinPackCard";
import { SkinCard } from "../components/SkinCard";
import { axiosAuth } from "../interceptors";
import { GridView as GridViewIcon } from "@mui/icons-material";
import useTitle from "../hooks/useTitle";
import useDisplaySize from "../hooks/useDisplaySize";
import SearchBox from "../components/Home/SearchBox";
import { Skin, SkinPack, Tag } from "../utils/interfaces";

export function Home() {
  const { loggedIn, setAlert, setAlertMessage, setAlertType, admin } =
    useContext(AppContext);

  const matches = useDisplaySize();
  useTitle("Home");

  const videoRef = useRef<any[]>([]);

  const [loadingSkinPack, setLoadingSkinPack] = useState<boolean>(false);
  const [loadingSkins, setLoadingSkins] = useState<boolean>(false);

  const [skinPackList, setSkinPackList] = useState<SkinPack[]>([]);
  const [subSkinPackList, setSubSkinPackList] = useState<
    {
      skinPack: SkinPack;
      order: number;
    }[][]
  >([]);

  const [paginationSkinPackList, setPaginationSkinPackList] = useState<
    SkinPack[]
  >([]);
  const [paginationCount, setPaginationCount] = useState<number>(0);
  const [packAmount, setPackAmount] = useState<number>(8);

  const [skinsList, setSkinsList] = useState<Skin[]>([]);
  const [variationList, setVariationList] = useState<SkinPack[]>([]);
  const [currentVariation, setCurrentVariation] = useState<SkinPack>();
  const [currentSkinPacks, setCurrentSkinPacks] = useState<SkinPack[]>([]);
  const [currentSkin, setCurrentSkin] = useState<Skin>();

  const [gridWidth, setGridWidth] = useState("M");
  const [sizeFilter, setSizeFilter] = useState([]);
  const [animate, setAnimate] = useState(false);

  const [inputFilters, setInputFilters] = useState<any>({});
  const [tagFilters, setTagFilters] = useState<{
    tag?: Tag;
    tagType?: string;
  }>({});

  const [filteredPacks, setFilteredPacks] = useState<SkinPack[]>([]);

  const [favorites, setFavorites] = useState<string[]>(
    JSON.parse(localStorage.getItem("favorites") || "[]")
  );

  function handleGridWidth(width: string) {
    setGridWidth(width);
  }

  async function handleSkinPackClick(skin: SkinPack) {
    let newCurrentPack: SkinPack[] = [];

    skin = await getSkinPack(skin._id);

    if (currentSkinPacks.length > 1) {
      if (
        !currentSkinPacks[0].subPacks?.find((sp) => sp.subPack._id === skin._id)
      ) {
        newCurrentPack = [skin];
      } else {
        newCurrentPack = [currentSkinPacks[0], skin];
      }
    } else {
      if (
        currentSkinPacks.length > 0 &&
        currentSkinPacks[0].subPacks?.find((sp) => sp.subPack._id === skin._id)
      ) {
        newCurrentPack = [...currentSkinPacks, skin];
      } else {
        newCurrentPack = [skin];
      }
    }

    setSizeFilter([]);
    setCurrentSkinPacks(newCurrentPack);
    setSkinListsBySkinPack(skin);
  }

  function handleVariationChange(event: any) {
    setCurrentVariation(event.target.value);
    setSkinsList(event.target.value.skins);
  }

  useEffect(() => {
    setLoadingSkinPack(true);
    axiosAuth
      .get(process.env.REACT_APP_BACKEND_URL + "/skin")
      .then((response) => {
        if (favorites.length > 0) {
          response.data = response.data.map((sp: SkinPack) => {
            if (favorites.includes(sp._id)) {
              return { ...sp, favorite: true };
            } else {
              return sp;
            }
          });

          response.data.sort((a: SkinPack, b: SkinPack) => {
            if (a.favorite && !b.favorite) {
              return -1;
            } else if (!a.favorite && b.favorite) {
              return 1;
            } else {
              return 0;
            }
          });
        }
        setSkinPackList(response.data);
        setPaginationSkinPackList(response.data.slice(0, packAmount));
        setPaginationCount(Math.ceil(response.data.length / packAmount));
        setLoadingSkinPack(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          axiosAuth
            .get(process.env.REACT_APP_BACKEND_URL + "/skin", {
              headers: {
                "x-access-token": "not-logged-in",
              },
            })
            .then((response) => {
              if (favorites.length > 0) {
                response.data = response.data.map((sp: SkinPack) => {
                  if (favorites.includes(sp._id)) {
                    return { ...sp, favorite: true };
                  } else {
                    return sp;
                  }
                });

                response.data.sort((a: SkinPack, b: SkinPack) => {
                  if (a.favorite && !b.favorite) {
                    return -1;
                  } else if (!a.favorite && b.favorite) {
                    return 1;
                  } else {
                    return 0;
                  }
                });
              }
              setSkinPackList(response.data);
              setPaginationSkinPackList(response.data.slice(0, packAmount));
              setPaginationCount(Math.ceil(response.data.length / packAmount));
            })
            .catch((error) => {
              setAlertMessage("Could not load signs.");
              setAlertType("error");
              setAlert(true);
            });
        } else {
          setAlertMessage("Could not load signs.");
          setAlertType("error");
          setAlert(true);
        }
        setLoadingSkinPack(false);
      });
  }, []);

  useEffect(() => {
    videoRef.current.forEach((video: any) => {
      if (!video) return;
      if (animate) {
        video.play();
      } else {
        video.pause();
      }
    });
  }, [animate]);

  useEffect(() => {
    let tempSkinPackList = skinPackList;
    if (tagFilters.tag) {
      tempSkinPackList = skinPackList.filter((sp) =>
        sp.tags?.find((t) => t._id === tagFilters.tag?._id)
      );
    } else if (tagFilters.tagType) {
      tempSkinPackList = skinPackList.filter((sp) =>
        sp.tags?.find((t) => t.type === tagFilters.tagType)
      );
    }

    if (inputFilters.skinpacks) {
      tempSkinPackList = tempSkinPackList
        ?.filter((sp) =>
          sp.name.toLowerCase().includes(inputFilters.skinpacks.toLowerCase())
        )
        .slice(0, packAmount);
    }

    setFilteredPacks(tempSkinPackList);
    setPaginationSkinPackList(tempSkinPackList.slice(0, packAmount));
    setPaginationCount(Math.ceil(tempSkinPackList.length / packAmount));
  }, [inputFilters, skinPackList, tagFilters, packAmount]);

  function copyToClipboard(skin: Skin) {
    setCurrentSkin(skin);
    navigator.clipboard.writeText(skin.cdnUrl || skin.url);
    setAlertMessage(skin.name + " copied to clipboard.");
    setAlertType("success");
    setAlert(true);
  }

  function setSkinListsBySkinPack(skinPack: any) {
    if (skinPack.skins.filter((s: any) => !s.skin?.display).length > 0) {
      setSkinsList(skinPack.skins);
    } else if (skinPack.subPacks.filter((sp: any) => sp.variation).length > 0) {
      setSkinsList(
        skinPack.subPacks.filter((sp: any) => sp.variation)[0].subPack.skins
      );
      setCurrentVariation(
        skinPack.subPacks.filter((sp: any) => sp.variation)[0].subPack
      );
    } else {
      setSkinsList([]);
    }

    setVariationList(
      skinPack.subPacks
        .filter((sp: any) => sp.variation)
        .map((sp: any) => sp.subPack)
    );

    if (skinPack.subPacks.length > 0) {
      let newSubPacks: {
        skinPack: SkinPack;
        order: number;
      }[] = [];

      skinPack.subPacks.forEach((subPack: any) => {
        if (!subPack.variation) {
          newSubPacks.push({
            skinPack: subPack.subPack,
            order: subPack.order,
          });
        }
      });

      setSubSkinPackList([newSubPacks]);
    } else {
      let sp = skinPackList.find((sp) => sp._id === skinPack._id);

      if (sp) {
        let newSubPacks: {
          skinPack: SkinPack;
          order: number;
        }[] = [];

        skinPack.subPacks.forEach((subPack: any) => {
          if (!subPack.variation) {
            newSubPacks.push({
              skinPack: subPack.subPack,
              order: subPack.order,
            });
          }
        });

        setSubSkinPackList([newSubPacks]);
      }
    }
  }

  function paginationChange(event: any, value: number) {
    setPaginationSkinPackList(
      filteredPacks.slice((value - 1) * packAmount, value * packAmount)
    );
  }

  const onTagFilter = useCallback((tag: Tag, tagType: string) => {
    setTagFilters({ tag, tagType });
  }, []);

  async function getSkinPack(id: string): Promise<SkinPack> {
    setLoadingSkins(true);
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axiosAuth.get(
          process.env.REACT_APP_BACKEND_URL + "/skin/" + id
        );
        setLoadingSkins(false);
        resolve(response.data);
      } catch (error: any) {
        if (error.response.status === 401) {
          try {
            const response = await axiosAuth.get(
              process.env.REACT_APP_BACKEND_URL + "/skin/" + id,
              {
                headers: {
                  "x-access-token": "not-logged-in",
                },
              }
            );
            setLoadingSkins(false);
            resolve(response.data);
          } catch (error) {
            setAlertMessage("Could not load skin pack.");
            setAlertType("error");
            setAlert(true);
            setLoadingSkins(false);
          }
        } else {
          setAlertMessage("Could not load skin pack.");
          setAlertType("error");
          setAlert(true);
          setLoadingSkins(false);
          reject();
        }
      }
    });
  }

  useEffect(() => {
    localStorage.setItem("favorites", JSON.stringify(favorites));
  }, [favorites]);

  function toggleFavorite(_id: string) {
    if (favorites.includes(_id)) {
      setFavorites(favorites.filter((f) => f !== _id));
    } else {
      setFavorites([...favorites, _id]);
    }
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <Paper elevation={0.75} sx={{ p: 2, width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            mt: -2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            {!matches["880"] && (
              <Typography
                variant={matches["1080"] ? "h5" : "h4"}
                sx={{ fontWeight: "bold", mr: 4 }}
              >
                Packs
              </Typography>
            )}
            {loggedIn && admin && (
              <Button
                variant="contained"
                sx={{ height: 40, px: 2, minHeight: 0, minWidth: 0, py: 1 }}
                component={Link}
                to="/upload"
              >
                {matches["1300"]
                  ? !matches["1150"] && "Upload"
                  : "Upload Skinpack"}
                <UploadIcon sx={[!matches["1150"] && { ml: 1 }]} />
              </Button>
            )}
          </Box>
          <Box
            sx={[
              { display: "flex", alignItems: "center", flexWrap: "wrap" },
              matches["880"] && { flex: 1, justifyContent: "flex-end" },
              matches["880"] &&
                !loggedIn &&
                !admin && { justifyContent: "center" },
            ]}
            key="box ben"
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ display: "flex", alignItems: "center", mr: 3, mt: 2 }}>
                <Tooltip
                  title="Grid Size"
                  placement="top"
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "primary.main",
                        "& .MuiTooltip-arrow": {
                          color: "primary.main",
                        },
                      },
                    },
                  }}
                >
                  <GridViewIcon sx={{ mr: 2 }} />
                </Tooltip>
                {[4, 8].map((option: number) => (
                  <Button
                    variant={packAmount === option ? "contained" : "outlined"}
                    key={option}
                    onClick={() => {
                      setPackAmount(option);
                      setPaginationSkinPackList(skinPackList.slice(0, option));
                      setPaginationCount(
                        Math.ceil(skinPackList.length / option)
                      );
                    }}
                    sx={{
                      mr: 1,
                      px: matches[1300] ? 2 : 3,
                      py: 1,
                      minHeight: 0,
                      minWidth: 0,
                    }}
                  >
                    {option}
                  </Button>
                ))}
              </Box>

              <SearchBox
                inputFilters={inputFilters}
                setInputFilters={setInputFilters}
                onTagFilter={onTagFilter}
              />
            </Box>
            <Pagination
              count={paginationCount}
              color={"primary"}
              onChange={paginationChange}
              shape="rounded"
              sx={{ mt: 2 }}
              boundaryCount={0}
              siblingCount={matches["1080"] ? 0 : 1}
            />
          </Box>
        </Box>
        {loadingSkinPack ? (
          <Box
            sx={{
              width: "100%",
              height: 256,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={1} sx={{ mt: 1 }}>
            {paginationSkinPackList
              .sort((a: any, b: any) => a.order - b.order)
              .map((skinPack) => (
                <SkinPackCard
                  skinPack={skinPack}
                  isSelected={currentSkinPacks.find(
                    (sp) => sp._id === skinPack._id
                  )}
                  onClick={handleSkinPackClick}
                  publicPack={skinPack.public}
                  tags={skinPack.tags}
                  favorites={favorites}
                  toggleFavorite={toggleFavorite}
                />
              ))}
          </Grid>
        )}
      </Paper>

      {currentSkinPacks.length > 0 && currentSkinPacks[0].description && (
        <Paper elevation={0.75} sx={{ p: 2, mt: 2 }}>
          {!matches["880"] && (
            <Typography
              variant={matches["1080"] ? "h5" : "h4"}
              sx={{ mb: 2, fontWeight: "bold" }}
            >
              Description
            </Typography>
          )}
          <Typography variant="body1">
            {currentSkinPacks[0].description}
          </Typography>
        </Paper>
      )}

      {subSkinPackList.map(
        (subSkinPack) =>
          subSkinPack.length > 0 && (
            <Paper elevation={0.75} sx={{ p: 2, mt: 2 }}>
              {!matches["880"] && (
                <Typography
                  variant={matches["1080"] ? "h5" : "h4"}
                  sx={{ mb: 2, fontWeight: "bold" }}
                >
                  Sub Packs
                </Typography>
              )}
              <Grid container spacing={1}>
                {subSkinPack
                  .sort((a: any, b: any) => a.order - b.order)
                  .map((skinPack) => (
                    <SkinPackCard
                      skinPack={skinPack.skinPack}
                      isSelected={currentSkinPacks.find(
                        (sp) => sp._id === skinPack.skinPack._id
                      )}
                      onClick={handleSkinPackClick}
                    />
                  ))}
              </Grid>
            </Paper>
          )
      )}

      {skinsList.filter((s: any) => !s.skin?.display).length > 0 && (
        <>
          <Paper elevation={0.75} sx={{ p: 2, pt: 0, mt: 2, width: "100%" }}>
            <Filterbar
              gridWidth={gridWidth}
              setGridWidth={handleGridWidth}
              sizeFilter={sizeFilter}
              setSizeFilter={setSizeFilter}
              setAnimate={setAnimate}
              packSizes={skinsList
                .filter((s: any) => !s.skin?.display)
                .map((s: any) => s.skin.size || "Other")}
              variationList={variationList}
              currentVariation={currentVariation}
              handleVariationChange={handleVariationChange}
            />

            {loadingSkins ? (
              <Box
                sx={{
                  width: "100%",
                  height: 256,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : sizeFilter.length === 0 ? (
              ["1x1", "2x1", "4x1", "6x1", "Other"].map(
                (size: any) =>
                  skinsList.filter(
                    (s: any) =>
                      !s.skin?.display &&
                      (s.skin.size === size ||
                        (size === "Other" &&
                          s.skin.size !== "1x1" &&
                          s.skin.size !== "2x1" &&
                          s.skin.size !== "4x1" &&
                          s.skin.size !== "6x1"))
                  ).length > 0 && (
                    <>
                      <Box
                        sx={{
                          mt: 3,
                          mb: 2,
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Divider
                          sx={{
                            mr: 1.8,
                            bgcolor: "#A63C46",
                            flex: 1,
                            height: 2,
                          }}
                        />
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                          {size}
                        </Typography>
                        <Divider
                          sx={{
                            ml: 2,
                            bgcolor: "#A63C46",
                            flex: 50,
                            height: 2,
                          }}
                        />
                      </Box>

                      <Grid container spacing={1}>
                        {size !== "Other"
                          ? skinsList
                              .filter(
                                (s: any) =>
                                  !s.skin?.display && s.skin.size === size
                              )
                              .sort((a: any, b: any) => a.order - b.order)
                              .map((skin: any) => (
                                <Grid
                                  item
                                  xs={
                                    12 /
                                    (gridWidth === "S"
                                      ? size === "1x1"
                                        ? 8
                                        : 6
                                      : gridWidth === "M"
                                      ? size === "1x1"
                                        ? 6
                                        : 4
                                      : size === "1x1"
                                      ? 4
                                      : 2)
                                  }
                                  key={skin.skin.name}
                                >
                                  <SkinCard
                                    skin={skin.skin}
                                    currentSkin={currentSkin}
                                    onClick={copyToClipboard}
                                    videoRef={videoRef}
                                    animate={animate}
                                  />
                                </Grid>
                              ))
                          : skinsList
                              .filter(
                                (s: any) =>
                                  !s.skin?.display &&
                                  s.skin.size !== "1x1" &&
                                  s.skin.size !== "2x1" &&
                                  s.skin.size !== "4x1" &&
                                  s.skin.size !== "6x1"
                              )
                              .sort((a: any, b: any) => a.order - b.order)
                              .map((skin: any) => (
                                <Grid
                                  item
                                  xs={
                                    12 /
                                    (gridWidth === "S"
                                      ? 8
                                      : gridWidth === "M"
                                      ? 6
                                      : 4)
                                  }
                                  key={skin.skin.name}
                                >
                                  <SkinCard
                                    skin={skin.skin}
                                    currentSkin={currentSkin}
                                    onClick={copyToClipboard}
                                    videoRef={videoRef}
                                    animate={animate}
                                  />
                                </Grid>
                              ))}
                      </Grid>
                    </>
                  )
              )
            ) : (
              sizeFilter.sort().map(
                (size: any) =>
                  skinsList.filter(
                    (s: any) =>
                      !s.skin?.display &&
                      (s.skin.size === size ||
                        (size === "Other" &&
                          s.skin.size !== "1x1" &&
                          s.skin.size !== "2x1" &&
                          s.skin.size !== "4x1" &&
                          s.skin.size !== "6x1"))
                  ).length > 0 && (
                    <>
                      <Box
                        sx={{
                          mt: 3,
                          mb: 2,
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Divider
                          sx={{
                            mr: 1.8,
                            bgcolor: "#A63C46",
                            flex: 1,
                            height: 2,
                          }}
                        />
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                          {size}
                        </Typography>
                        <Divider
                          sx={{
                            ml: 2,
                            bgcolor: "#A63C46",
                            flex: 50,
                            height: 2,
                          }}
                        />
                      </Box>

                      <Grid container spacing={1}>
                        {size !== "Other"
                          ? skinsList
                              .filter(
                                (s: any) =>
                                  !s.skin?.display && s.skin.size === size
                              )
                              .sort((a: any, b: any) => a.order - b.order)
                              .map((skin: any) => (
                                <Grid
                                  item
                                  xs={
                                    12 /
                                    (gridWidth === "S"
                                      ? size === "1x1"
                                        ? 8
                                        : 6
                                      : gridWidth === "M"
                                      ? size === "1x1"
                                        ? 6
                                        : 4
                                      : size === "1x1"
                                      ? 4
                                      : 2)
                                  }
                                  key={skin.skin.name}
                                >
                                  <SkinCard
                                    skin={skin.skin}
                                    currentSkin={currentSkin}
                                    onClick={copyToClipboard}
                                    videoRef={videoRef}
                                    animate={animate}
                                  />
                                </Grid>
                              ))
                          : skinsList
                              .filter(
                                (s: any) =>
                                  !s.skin?.display &&
                                  s.skin.size !== "1x1" &&
                                  s.skin.size !== "2x1" &&
                                  s.skin.size !== "4x1" &&
                                  s.skin.size !== "6x1"
                              )
                              .sort((a: any, b: any) => a.order - b.order)
                              .map((skin: any) => (
                                <Grid
                                  item
                                  xs={
                                    12 /
                                    (gridWidth === "S"
                                      ? 8
                                      : gridWidth === "M"
                                      ? 6
                                      : 4)
                                  }
                                  key={skin.skin.name}
                                >
                                  <SkinCard
                                    skin={skin.skin}
                                    currentSkin={currentSkin}
                                    onClick={copyToClipboard}
                                    videoRef={videoRef}
                                    animate={animate}
                                  />
                                </Grid>
                              ))}
                      </Grid>
                    </>
                  )
              )
            )}
          </Paper>
        </>
      )}

      <Container
        maxWidth="lg"
        sx={{
          mt: 8,
          position: "relative",
          bottom: 8,
          right: 0,
          left: 0,
          flex: 1,
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{ mr: 2, fontSize: matches["1400"] ? "12px" : "15px" }}
          >
            TMSigns is not affiliated with or endorsed by Nadeo or Ubisoft. All
            public signs on TMSigns are available for general use.
          </Typography>
          <Box>
            <Typography
              sx={{ mr: 2, fontSize: matches["1400"] ? "12px" : "15px" }}
            >
              Feel free to{" "}
              <a
                href="https://discord.com/users/130995736446763008"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "#A63C46" }}
              >
                reach out to me on Discord
              </a>{" "}
              for feedback and suggestions.
            </Typography>
          </Box>
          <Box
            sx={{ height: matches["1400"] ? 24 : 32, mt: 1, cursor: "pointer" }}
            onClick={() => {
              window.open("https://ko-fi.com/hikoeu", "_blank");
            }}
          >
            <img
              src="https://storage.ko-fi.com/cdn/brandasset/kofi_button_dark.png?_gl=1*sdnu3p*_ga*MTEwMjcyNTE0Ny4xNjk3MTAxMTU2*_ga_M13FZ7VQ2C*MTY5NzEwMTU0NS4xLjEuMTY5NzEwMzMyNC4yLjAuMA.."
              alt="ko-fi donate link"
              height={" 100%"}
              width={"auto"}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
