import { axiosAuth } from "../interceptors";
import axios from "axios";
import { setSession, removeSession, setTokens } from "./storageService";

export function loginUser(user: any) {
  return new Promise((resolve, reject) => {
    axios.post(process.env.REACT_APP_BACKEND_URL + "/user/login", {
      username: user.username,
      password: user.password
    }).then(response => {
      setSession({
        accessToken: response.headers["x-access-token"],
        refreshToken: response.headers["x-refresh-token"],
        _id: response.data._id,
        name: response.data.name,
        roles: response.data.roles
      });

      return resolve(response.data);
    }).catch(err => {
      return reject(err);
    });
  });
}

export function logoutUser() {
  removeSession();
  window.location.href = "/";
  return;
}

export function refreshTokens() {
  return new Promise((resolve, reject) => {
    axiosAuth.post(process.env.REACT_APP_BACKEND_URL + "/user/refresh-tokens", {}, {
      headers: {
        "x-refresh-token": localStorage.getItem("x-refresh-token"),
        "id": localStorage.getItem("_id")
      }
    }).then(response => {
      setTokens(response.data)

      return resolve(response.data);
    }).catch(err => {
      return reject(err);
    });
  });
}