import { Paper, Box, IconButton } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../context/appContext";
import { useNavigate } from "react-router-dom";
import { ReactComponent as TMSLogo } from "../assets/TMSLogo.svg";
import { ReactComponent as Kofi } from "../assets/kofi.svg";
import { ReactComponent as Discord } from "../assets/discord.svg";
import { Person as PersonIcon } from "@mui/icons-material";

export function Navbar() {
  const { loggedIn } = useContext(AppContext);
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: 64,
        m: 1,
        ml: 0,
        display: "flex",
        position: "fixed",
        top: 0,
        bottom: 0,
      }}
    >
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          p: 1,
          pt: 3,
          pb: 2,
          flex: 1,
        }}
        elevation={1}
      >
        <TMSLogo
          width={42}
          onClick={() => navigate("/")}
          style={{
            cursor: "pointer",
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderTop: "1px solid darkgrey",
            pt: 1,
          }}
        >
          <PersonIcon
            sx={{ fontSize: 32, cursor: "pointer", mb: 1, color: 'white' }}
            onClick={() => navigate(loggedIn ? "/profile" : "/login")}
          />
          <Discord
            onClick={() =>
              window.open(
                "https://discord.com/users/130995736446763008",
                "_blank"
              )
            }
            style={{ cursor: "pointer", marginBottom: 12 }}
            width={32}
          />
          <Kofi
            onClick={() => window.open("https://ko-fi.com/hikoeu", "_blank")}
            style={{ cursor: "pointer", marginLeft: 6 }}
            width={36}
          />
        </Box>
      </Paper>
    </Box>
  );
}
