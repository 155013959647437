import { createContext } from 'react';

export const AppContext = createContext({
  loggedIn: false,
  setLoggedIn: (value: boolean) => {},
  alert: false,
  setAlert: (value: boolean) => {},
  alertMessage: '',
  setAlertMessage: (value: string) => {},
  alertType: 'success',
  setAlertType: (value: string) => {},
  loading: false,
  setLoading: (value: boolean) => {},
  loadingMessage: '',
  setLoadingMessage: (value: string) => {},
  admin: false,
  setAdmin: (value: boolean) => {},
});