import { useRef, useEffect } from "react";

function useTitle(title: string, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = `TMSigns - ${title}`;
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = `TMSigns - ${defaultTitle.current}`;
      }
    }, [prevailOnUnmount]);
}

export default useTitle;