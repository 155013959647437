import { useState, useEffect } from "react";

const useContextMenu = () => {
  const [clicked, setClicked] = useState(false);
  const [points, setPoints] = useState({
    x: 0,
    y: 0,
  });
  const [subMenu, setSubMenu] = useState("");
  useEffect(() => {
    const handleClick = () => { 
      setClicked(false) ;
      setSubMenu(""); 
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);
  return {
    clicked,
    setClicked,
    points,
    setPoints,
    subMenu,
    setSubMenu,
  };
};

export default useContextMenu;
